import { CommandContribution, MenuContribution } from '@theia/core';
import { FrontendApplicationContribution, KeybindingContribution, LabelProviderContribution, NavigatableWidgetOptions, OpenHandler, WidgetFactory } from '@theia/core/lib/browser';
import URI from '@theia/core/lib/common/uri';
import { ContainerModule, interfaces } from 'inversify';
import { XmlViewerContribution } from './xml-viewer-contribution';
import { XmlViewerLabelProviderContribution } from './xml-viewer-label-provider-contribution';
import { XmlViewerManager } from './xml-viewer-manager';
import { XmlViewerOutlineContribution } from './xml-viewer-outline-contribution';
import { XmlViewerOptions, XmlViewerWidget } from './xml-viewer-widget';

export default new ContainerModule(bind => {
    bindXmlViewerWidget(bind);
});

export function bindXmlViewerWidget(bind: interfaces.Bind) {
    bind(LabelProviderContribution).to(XmlViewerLabelProviderContribution);
    bind(MenuContribution).to(XmlViewerContribution);
    bind(CommandContribution).to(XmlViewerContribution);
    bind(KeybindingContribution).to(XmlViewerContribution);
    bind(FrontendApplicationContribution).to(XmlViewerContribution);

    bind<XmlViewerManager>(XmlViewerManager).to(XmlViewerManager).inSingletonScope();
    
    bind(OpenHandler).to(XmlViewerManager).inSingletonScope();

    bind(XmlViewerOutlineContribution).toSelf().inSingletonScope();
    bind(FrontendApplicationContribution).toService(XmlViewerOutlineContribution);

    bind<WidgetFactory>(WidgetFactory).toDynamicValue(context => ({
        id: XmlViewerWidget.WIDGET_ID,
        createWidget: (options: NavigatableWidgetOptions) => {

            const xmlContainer = createXmlContainer(
                context.container,
                XmlViewerWidget,
            );

            // Bind options.
            const uri = new URI(options.uri);
            xmlContainer.bind(XmlViewerOptions).toConstantValue({ uri });

            return xmlContainer.get(XmlViewerWidget);
        }
    }));

}

export function createXmlContainer(parent: interfaces.Container, xmlViewerWidget: interfaces.Newable<XmlViewerWidget>): interfaces.Container {
    const container = parent.createChild();
    container.bind(xmlViewerWidget).toSelf();

    return container;
}
import { ViewportContext } from '@modelbroker/diagram-viewport';
import React, { useMemo } from 'react';
import { Circle, Line, Path } from 'react-konva';
import { Extent } from '../proteus/Extent';
import { getPathMiddlePoint, ORIGO, Position } from '../proteus/Position';
import { childrenByTagName, firstChildByTagName, getBoundingBox, getCoordinates, getExtent, getId, getPosition, readShapeExtents } from '../proteus/ProteusXML';

interface Props {
    model: Document;
}

const DISPL = 3 - Math.sqrt(9 - 0.25);

export function ConnectivityRenderer(props: Props) {
    const { model } = props;
    const { positions, connections } = useMemo(() => collect(model), [model]);
    return <ViewportContext.Consumer>{({scale}) => {
        const strokeWidth = 2 / scale;
        return connections.flatMap(({ from, to, type }, index) => {
            const fromPos = positions[from];
            if (!fromPos)
                return [];
            const toPos = positions[to];
            if (!toPos)
                return [];
            const len = Math.hypot(fromPos.x - toPos.x, fromPos.y - toPos.y);
            /*return [<Arrow
                key={index}
                points={[
                    fromPos.x, fromPos.y,
                    0.5 * (fromPos.x + toPos.x) + 0.02 * (toPos.y - fromPos.y), 0.5 * (fromPos.y + toPos.y) - 0.02 * (toPos.x - fromPos.x),
                    toPos.x, toPos.y
                ]}
                stroke={type === 'Pipeline' ? 'blue' : type === 'Signal' ? 'red' : 'green'}
                strokeWidth={2 / scale}
                tension={0.5}
                pointerLength={10 / scale}
                pointerWidth={10 / scale}
            />]*/
            const radius = 3 * len;
            const color = type === 'Pipeline' ? '#007FFF' : type === 'Signal' ? 'red' : type === 'Reference' ? 'orange' : 'green';
            const dirX = (fromPos.x - toPos.x) / len;
            const dirY = (fromPos.y - toPos.y) / len;
            const centerX = 0.5 * (fromPos.x + toPos.x) + dirY * len * DISPL;
            const centerY = 0.5 * (fromPos.y + toPos.y) - dirX * len * DISPL;
            return [<Path
                key={'p' + index}
                data={`M${fromPos.x},${fromPos.y} A${radius},${radius} 0 0 0 ${toPos.x},${toPos.y}`}
                stroke={color}
                strokeWidth={strokeWidth}
            />,
            <Line
                key={'a' + index}
                points={[
                    centerX + (dirY + dirX * 2) * 5 / scale, centerY + (-dirX + dirY * 2) * 5 / scale,
                    centerX, centerY,
                    centerX + (-dirY + dirX * 2) * 5 / scale, centerY + (dirX + dirY * 2) * 5 / scale
                ]}
                stroke={color}
                strokeWidth={strokeWidth}
            />]
        }).concat(Object.values(positions).map((position, index) =>
            <Circle
                key={'n' + index}
                x={position.x}
                y={position.y}
                radius={5 / scale}
                stroke='pink'
                strokeWidth={strokeWidth}
            />
        ));
    }}</ViewportContext.Consumer>;
}

interface Connection {
    from: string;
    to: string;
    type: 'ChildComponent' | 'Pipeline' | 'Signal' | 'Reference';
}

interface CollectResult {
    positions: { [key: string]: Position };
    connections: Connection[];
}

function collect(model: Document) {
    const shapeExtents = readShapeExtents(model)
    const result: CollectResult = {
        positions: {},
        connections: []
    };
    collectRec(result, model.getRootNode() as Element, shapeExtents);
    return result;
}

function isComponent(name: string) {
    switch (name) {
        case 'PipingComponent':
        case 'ProcessInstrument':
        case 'PipeConnectorSymbol':
        case 'PipeOffPageConnector':
        case 'SignalLine':
        case 'CenterLine':
        case 'CrossPageConnection':
        case 'SignalConnectorSymbol':
        case 'SignalOffPageConnector':
        case 'Nozzle':
        case 'Equipment':
        case 'InstrumentComponent':
        case 'PipingNetworkSystem':
        case 'Component':
        case 'PropertyBreak':
        case 'ActuatingSystemComponent':
        case 'ProcessSignalGeneratingSystemComponent':
        case 'ProcessInstrumentationFunction':
        case 'InformationFlow':
            return true;
        default:
            return false;
    }
}


function collectRec(result: CollectResult, element: Element, shapeExtents: Map<string, Extent>) {
    switch (element.localName) {
        // Ignore
        case 'PlantInformation':
        case 'Drawing':
        case 'Label':
        case 'ShapeCatalogue':

        case 'ConnectionPoints':
        case 'Extent':
        case 'Coordinate':
        case 'Position':
        case 'GenericAttributes':
        case 'Presentation':
        case 'PersistentID':
        case 'LinkedPersistentID':
        case 'Description':
        case 'NominalDiameter':
        case 'MinimumDesignTemperature':
        case 'MaximumDesignTemperature':
        case 'Connection':

        case 'Line':
        case 'Text':
        case 'Circle':
        case 'TrimmedCurve':
        case 'PolyLine':
            break;

        case 'PipingNetworkSegment': {
            const children: string[] = [];
            const connection = firstChildByTagName(element, "Connection") as Element;
            if (connection) {
                const from = connection.getAttribute("FromID");
                if (from)
                    children.push(from);
            }
            element.childNodes.forEach(node => {
                if (!(node instanceof Element))
                    return;
                const element = node as Element;
                if (!isComponent(element.localName))
                    return;
                const id = element.getAttribute("ID");
                if (id)
                    children.push(id);
            });
            if (connection) {
                const to = connection.getAttribute("ToID");
                if (to)
                    children.push(to);
            }
            for (let i = 0; i < children.length - 1; ++i) {
                const a = children[i];
                const b = children[i + 1];
                if (a !== b)
                    result.connections.push({
                        from: a,
                        to: b,
                        type: 'Pipeline'
                    });
            }

            collectChildren(result, element, shapeExtents);
        } break;
        default:
            console.log(element.localName);
        case 'ActuatingSystem':
        case 'ProcessSignalGeneratingSystem': {
            const children = []
            children.push(...childrenByTagName(element, 'ActuatingSystemComponent'));
            children.push(...childrenByTagName(element, 'ProcessSignalGeneratingSystemComponent'));
            const ids = children.map((child) => child.getAttribute('ID')).filter(id => id);
            children.forEach((child) => {
                const associations = childrenByTagName(child, 'Association') as Element[];
                associations.forEach((association) => {
                    const type = association.getAttribute('Type');
                    const itemId = association.getAttribute('ItemID');
                    if (type && itemId && type === 'refers to') {
                        ids.forEach((id) => {
                            result.connections.push({
                                from: id!,
                                to: itemId,
                                type: 'Reference'
                            });
                        })
                    }
                })
            })

            collectChildren(result, element, shapeExtents);
            break;
        }
        case 'PlantModel':
        case 'PipingNetworkSystem':
        case undefined:
            collectChildren(result, element, shapeExtents);
            break;
        case 'InformationFlow':
        case 'SignalLine': {
            const id = getId(element);
            if (!id)
                break;
            const centerLine = firstChildByTagName(element, "CenterLine");
            if (!centerLine)
                break;
            const position = getPathMiddlePoint(getCoordinates(centerLine));
            result.positions[id] = position;

            const connection = firstChildByTagName(element, "Connection") as Element;
            if (connection) {
                const from = connection.getAttribute("FromID");
                if (from)
                    result.connections.push({
                        from,
                        to: id,
                        type: 'Signal'
                    });
                const to = connection.getAttribute("ToID");
                if (to)
                    result.connections.push({
                        from: id,
                        to,
                        type: 'Signal'
                    });
            }
        } break;
        case 'CenterLine': {
            const id = getId(element);
            if (id) {
                const position = getPathMiddlePoint(getCoordinates(element));
                result.positions[id] = position;
            }
        } break;
        case 'Equipment':
        case 'PipingComponent':
        case 'ProcessInstrument':
        case 'PipeConnectorSymbol':
        case 'PipeOffPageConnector':
        case 'CrossPageConnection':
        case 'SignalConnectorSymbol':
        case 'SignalOffPageConnector':
        case 'ActuatingSystemComponent':
        case 'ProcessSignalGeneratingSystemComponent':
        case 'ProcessInstrumentationFunction':
        case 'Nozzle':
        case 'InstrumentComponent':
        case 'Component':
        case 'PropertyBreak': {
            const id = getId(element);
            if (id) {
                let extent = getBoundingBox(element, shapeExtents, false);
                if (!extent)
                    extent = getExtent(element);
                if (extent)
                    result.positions[id] = extent.center();
                else {
                    const pos = getPosition(element);
                    if (pos !== ORIGO)
                        result.positions[id] = getPosition(element);
                }

                element.childNodes.forEach(node => {
                    if (!(node instanceof Element))
                        return;
                    const element = node as Element;
                    if (!isComponent(element.localName))
                        return;
                    if (element.localName === 'InformationFlow')
                        return;
                    if (element.localName === 'SignalOffPageConnector')
                        return;
                    const childId = element.getAttribute("ID");
                    if (childId)
                        result.connections.push({
                            from: id,
                            to: childId,
                            type: 'ChildComponent'
                        });
                });
            }
            collectChildren(result, element, shapeExtents);
            break;
        }
    }
}

function collectChildren(result: CollectResult, element: Element, shapeExtents: Map<string, Extent>) {
    element.childNodes.forEach((child) => {
        if (child instanceof Element) {
            collectRec(result, child, shapeExtents);
        }
    });
}

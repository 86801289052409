import { SelectionService } from "@theia/core";
import { SelectionCommandHandler } from "@theia/core/lib/common/selection-command-handler";
import { DexpiElement } from "./dexpi-element";

export interface DexpiElementSelection {

    dexpiElement: DexpiElement;
    source?: string;
}

export namespace DexpiElementSelection {

    export function is(arg: Object | undefined): arg is DexpiElementSelection {
        return typeof arg === 'object' && ('dexpiElement' in arg) && DexpiElement.is(arg['dexpiElement']);
    }

    export function create(selection: HTMLElement[], source?: string): DexpiElementSelection[] {
        return selection.map((element) => ({
            dexpiElement: element,
            source,
        }));
    }

    export class CommandHandler extends SelectionCommandHandler<DexpiElementSelection> {

        constructor(
            protected readonly selectionService: SelectionService,
            protected readonly options: SelectionCommandHandler.Options<DexpiElementSelection>
        ) {
            super(
                selectionService,
                arg => DexpiElementSelection.is(arg) ? arg : undefined,
                options
            );
        }
    }
}
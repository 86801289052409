import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { CommandRegistry, DefaultResourceProvider, Emitter, Event, ILogger, JsonRpcProxy, MessageService, Resource, SelectionService } from '@theia/core';
import { Navigatable, OpenerService, ReactWidget, Title, Widget } from '@theia/core/lib/browser';
import { default as URI, default as uri } from '@theia/core/lib/common/uri';
import { FileResource } from '@theia/filesystem/lib/browser';
import { GettingStartedCommand } from "@theia/getting-started/lib/browser/getting-started-contribution";
import { inject, injectable, postConstruct } from 'inversify';
import * as React from 'react';
import { DexpiElement } from "../../common/dexpi-element";
import { DexpiElementSelection } from "../../common/dexpi-element-selection";
import { LicenseServiceServer } from "../../common/license-service";
import { RemoteOpcNavigationServiceProvider } from "../../common/remote-opc-navigation-service-provider";
import { getId } from "../proteus/ProteusXML";
import ReactXmlViewer from './react/ReactXmlViewer';
import { XmlViewerOpenerOptions } from "./xml-viewer-manager";

export const XmlViewerOptions = Symbol(
    'XmlViewerOptions'
);
export interface XmlViewerOptions {
    uri: URI;
}

@injectable()
export class XmlViewerWidget extends ReactWidget implements Navigatable {

    protected readonly onModelChangedEmitter = new Emitter<Document | undefined>();
    readonly onModelChanged: Event<Document | undefined> = this.onModelChangedEmitter.event;

    protected resource: Resource;
    protected _proteusModel: Document | undefined;
    protected name: string;

    protected readonly onSelectionChangedEmitter = new Emitter<DexpiElement []>();
    readonly onSelectionChanged: Event<DexpiElement[]> = this.onSelectionChangedEmitter.event;
    protected selection: string[] = [];

    protected connectivityMode = false;

    protected colorMode = 0;

    protected searchingOPC = false;

    constructor(
        @inject(XmlViewerOptions)
        protected readonly options: XmlViewerOptions,
        @inject(DefaultResourceProvider)
        protected provider: DefaultResourceProvider,
        @inject(SelectionService) 
        protected selectionService: SelectionService,
        @inject(MessageService)
        protected messageService: MessageService,
        @inject(LicenseServiceServer) protected readonly licenseService: JsonRpcProxy<LicenseServiceServer>,
        @inject(CommandRegistry)
        protected readonly commandRegistry: CommandRegistry,
        @inject(RemoteOpcNavigationServiceProvider) 
        protected readonly opcNavigationService: RemoteOpcNavigationServiceProvider,
        @inject(OpenerService) 
        protected readonly openHandler: OpenerService,

        @inject(ILogger) protected readonly logger: ILogger,
    ) {
        super();
        this.id = XmlViewerWidget.WIDGET_ID + ':' + options.uri;
    }

    get uri(): URI {
        return this.options.uri;
    }

    getResourceUri(): URI | undefined {
        return this.uri;
    }
    createMoveToUri(resourceUri: uri): uri | undefined {
        throw new Error('Method not implemented.');
    }

    render(): React.ReactNode {
        if (this._proteusModel === undefined)
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <Spinner size={SpinnerSize.STANDARD}/>
                </div>
            );
        return (
            <ReactXmlViewer 
                proteusModel={this._proteusModel} 
                name={this.name} 
                selection={this.selection} 
                connectivityMode={this.connectivityMode} 
                colorMode={this.colorMode} 
                onElementSelection={(elements: HTMLElement[]) => this.onElementSelection(elements)}
                onFindConnectedOPC={(opc: Element) => this.navigateToConnected(opc)}
                notificationCallback={(msg: string) => this.messageService.warn(msg)} />
        );
    }

    setConnectivityMode(active: boolean) {
        this.connectivityMode = active;
        this.update();
    }

    setColorMode(active: number) {
        this.colorMode = active;
        this.update();
    }

    @postConstruct()
    protected init(): void {
        this.configureTitle(this.title);

        const uri = this.options.uri;
        this.logger.debug('init viewer ' + Date.now());
        this.provider.get(uri).then(
            resource => {
                this.resource = resource;
                this.load();
            },
            error => this.logger.error(`Could not create resource for uri ${uri}`, error)
        );

        this.toDispose.pushAll([
            this.selectionService.onSelectionChanged((selection) => {
                const selectedIds: string[] = [];
                if (this.isDexpiElementSelection(selection)) {
                    selection.forEach((el: DexpiElementSelection) => {
                        const id = getId(el.dexpiElement);
                        if (id) 
                            selectedIds.push(id)
                    });
                }
                this.setSelection(selectedIds);
            })
        ]);

        this.opcNavigationService.createIndex(uri.parent.toString()).catch((err:Error) => {});

        // Open properties view node_modules\@theia\property-view\src\browser\property-view-contribution.ts
        this.commandRegistry.executeCommand('property-view:toggle');
        this.update();
    }

    
    protected isDexpiElementSelection(selection: Object | undefined): boolean {
        return !!selection && Array.isArray(selection) && DexpiElementSelection.is(selection[0]);
    }

    protected configureTitle(title: Title<Widget>) {
        title.label = this.options.uri.path.base;
        title.caption = this.options.uri.toString();
        title.closable = true;
        this.title.iconClass = 'fa fa-window-maximize';
        this.name = this.options.uri.toString();
    }

    protected async load(): Promise<void> {
        let error = false;
        try {
            const fileResource = this.resource as FileResource;
            //console.log('reading fileResource contents ' + Date.now());
            const stringXml = await fileResource.readContents();
            //console.log('reading fileResource contents is done! ' + Date.now());
            const parser = new DOMParser();
            this.setProteusModel(parser.parseFromString(stringXml.trim(), 'application/xml'));
            //console.log('parsed proteus model! ' + Date.now());
            this.update();
        } catch (e) {
            console.error(`Loading ${this.resource.uri} failed.`, e);
            error = true;
            if (error) {
                console.log('error');
            }
        }
    }

    protected async navigateToConnected(opc: Element) {
        const opcId = getId(opc);
        if (!opcId)
            return;
        const folderUri = this.options.uri.parent.toString();
        const result = await this.opcNavigationService.findConnectedOpc(opcId, folderUri).catch((err: Error) => {});
        if (!result)
            return;
        const options: XmlViewerOpenerOptions = {
            mode: 'reveal',
            selection: [result.id]
        };
        const uri = new URI(result.fileUri);
        const opener = await this.openHandler.getOpener(uri);
        opener.open(uri, options);
    }
    
    getProteusModel(): Document | undefined {
        return this._proteusModel;
    }

    setProteusModel(model: Document | undefined) {
        if (this._proteusModel !== model) {
            this._proteusModel = model;
            this.onModelChangedEmitter.fire(this._proteusModel);
        }
    }

    setSelection(selection: string[]) {
        this.selection = selection;
        this.update();
    }

    protected onElementSelection(selectedElements: HTMLElement[]): void {
        this.updateGlobalSelection(DexpiElementSelection.create(selectedElements, this.id));
        this.onSelectionChangedEmitter.fire(selectedElements);
    }

    protected updateGlobalSelection(selection: Object | undefined): void {
        this.selectionService.selection = selection;
    }

    protected doOpenGettingStartedWidget = () => this.commandRegistry.executeCommand(GettingStartedCommand.id);
}

export namespace XmlViewerWidget {
    export const WIDGET_ID = 'mb-dexpi-xml-viewer'
}
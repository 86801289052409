import { ContextMenuRenderer, LabelProvider, NodeProps, TreeModel, TreeNode, TreeProps, TreeWidget } from "@theia/core/lib/browser";
import * as React from '@theia/core/shared/react';
import { PropertyDataService } from "@theia/property-view/lib/browser/property-data-service";
import { PropertyViewContentWidget } from "@theia/property-view/lib/browser/property-view-content-widget";
import { inject, injectable, postConstruct } from "inversify";
import { DexpiElement } from '../../common/dexpi-element';
import { DexpiPropertiesCategoryNode, DexpiPropertiesItemNode, DexpiPropertiesRoot, ROOT_ID } from "./dexpi-property-view-tree-items";

@injectable()
export class DexpiPropertyViewTreeWidget extends TreeWidget implements PropertyViewContentWidget {
    
    static readonly ID = 'dexpi-properties-tree-widget';
    static readonly LABEL = 'Dexpi Properties Tree';

    protected propertiesTree: Map<string, DexpiPropertiesCategoryNode>;
    protected currentSelection: Object | undefined;

    @inject(LabelProvider) protected readonly labelProvider: LabelProvider;

    constructor(
        @inject(TreeProps) readonly props: TreeProps,
        @inject(TreeModel) model: TreeModel,
        @inject(ContextMenuRenderer) protected readonly contextMenuRenderer: ContextMenuRenderer
    ) {
        super(props, model, contextMenuRenderer);

        model.root = {
            id: ROOT_ID,
            name: DexpiPropertyViewTreeWidget.LABEL,
            parent: undefined,
            visible: false,
            children: []
        } as DexpiPropertiesRoot;

        this.propertiesTree = new Map<string, DexpiPropertiesCategoryNode>();
    }

    @postConstruct()
    protected init(): void {
        super.init();

        this.id = DexpiPropertyViewTreeWidget.ID + '-treeContainer';
        this.addClass('treeContainer');

        this.fillPropertiesTree();
    }

    protected updateNeeded(selection: Object | undefined): boolean {
        return this.currentSelection !== selection;
    }

    updatePropertyViewContent(propertyDataService?: PropertyDataService, selection?: Object | undefined): void {
        if (this.updateNeeded(selection)) {
            this.currentSelection = selection;
            if (propertyDataService) {
                propertyDataService.providePropertyData(selection).then((element: DexpiElement) => {
                    this.fillPropertiesTree(element); // TODO: add support for multiple
                });
            }
        }
    }

    protected fillPropertiesTree(element?: DexpiElement): void {
        if (element) {
            this.propertiesTree.clear();
            const infoNode = this.createCategoryNode('info', 'Info');
            this.propertiesTree.set('info', infoNode);

            infoNode.children.push(this.createResultLineNode('elementName', 'ElementName', element.nodeName, infoNode));
            const id = DexpiElement.getId(element);
            if (id) {
                infoNode.children.push(this.createResultLineNode('id', 'ID', id, infoNode));
            }

            this.refreshModelChildren();
        }
    }

    protected createCategoryNode(categoryId: string, name: string): DexpiPropertiesCategoryNode {
        return {
            id: categoryId,
            parent: this.model.root as DexpiPropertiesRoot,
            name,
            children: [],
            categoryId,
            selected: false,
            expanded: true
        };
    }

    protected createResultLineNode(id: string, name: string, property: boolean | string | undefined, parent: DexpiPropertiesCategoryNode): DexpiPropertiesItemNode {
        return {
            id: `${parent.id}::${id}`,
            parent,
            name: name,
            property: property !== undefined ? String(property) : '',
            selected: false
        };
    }

    protected async refreshModelChildren(): Promise<void> {
        if (DexpiPropertiesRoot.is(this.model.root)) {
            this.model.root.children = Array.from(this.propertiesTree.values());
            this.model.refresh();
        }
    }

    protected renderCaption(node: TreeNode, props: NodeProps): React.ReactNode {
        if (DexpiPropertiesCategoryNode.is(node)) {
            return this.renderExpandableNode(node);
        } else if (DexpiPropertiesItemNode.is(node)) {
            return this.renderItemNode(node);
        }
        return undefined;
    }

    protected renderExpandableNode(node: DexpiPropertiesCategoryNode): React.ReactNode {
        return <React.Fragment>
            <div className={`theia-resource-tree-node-icon ${this.toNodeIcon(node)}`}></div>
            <div className={'theia-resource-tree-node-name theia-TreeNodeSegment theia-TreeNodeSegmentGrow'}>{this.toNodeName(node)}</div>
        </React.Fragment>;
    }

    protected renderItemNode(node: DexpiPropertiesItemNode): React.ReactNode {
        return <React.Fragment>
            <div className={`theia-resource-tree-node-icon ${this.toNodeIcon(node)}`}></div>
            <div className={'theia-resource-tree-node-name theia-TreeNodeSegment theia-TreeNodeSegmentGrow'}>{this.toNodeName(node)}</div>
            <div className={'theia-resource-tree-node-property theia-TreeNodeSegment theia-TreeNodeSegmentGrow'}>{this.toNodeDescription(node)}</div>
        </React.Fragment>;
    }

    protected createNodeAttributes(node: TreeNode, props: NodeProps): React.Attributes & React.HTMLAttributes<HTMLElement> {
        return {
            ...super.createNodeAttributes(node, props),
            title: this.getNodeTooltip(node)
        };
    }

    protected getNodeTooltip(node: TreeNode): string | undefined {
        if (DexpiPropertiesCategoryNode.is(node)) {
            return this.labelProvider.getName(node);
        } else if (DexpiPropertiesItemNode.is(node)) {
            return `${this.labelProvider.getName(node)}: ${this.labelProvider.getLongName(node)}`;
        }
        return undefined;
    }
}
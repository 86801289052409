import { createTreeContainer, LabelProviderContribution, TreeProps, TreeWidget } from "@theia/core/lib/browser";
import { PropertyDataService } from "@theia/property-view/lib/browser/property-data-service";
import { PropertyViewWidgetProvider } from "@theia/property-view/lib/browser/property-view-widget-provider";
import { interfaces } from "inversify";
import { DexpiPropertyDataService } from "./dexpi-property-data-service";
import { DexpiPropertyViewBlueprintWidget } from "./dexpi-property-view-blueprintjs-widget";
import { DexpiPropertiesLabelProvider } from "./dexpi-property-view-label-provider";
import { DexpiPropertyViewTreeWidget } from "./dexpi-property-view-tree-widget";
import { DexpiPropertyViewWidgetProvider } from "./dexpi-property-view-widget-provider";

const DEXPI_PROPERTY_VIEW_TREE_PROPS = {
    multiSelect: true,
    search: true,
} as TreeProps;

function createDexpiPropertyViewTreeWidget(parent: interfaces.Container): DexpiPropertyViewTreeWidget {
    const child = createTreeContainer(parent, DEXPI_PROPERTY_VIEW_TREE_PROPS);
    child.unbind(TreeWidget);
    child.bind(DexpiPropertyViewTreeWidget).toSelf().inSingletonScope();
    return child.get(DexpiPropertyViewTreeWidget);
}

function createDexpiPropertyViewBlueprintWidget(parent: interfaces.Container): DexpiPropertyViewBlueprintWidget {
    const child = createTreeContainer(parent, DEXPI_PROPERTY_VIEW_TREE_PROPS);
    child.unbind(TreeWidget);
    child.bind(DexpiPropertyViewBlueprintWidget).toSelf().inSingletonScope();
    return child.get(DexpiPropertyViewBlueprintWidget);
}

export function bindDexpiPropertyView(bind: interfaces.Bind): void {
    bind(LabelProviderContribution).to(DexpiPropertiesLabelProvider).inSingletonScope();
    bind(PropertyDataService).to(DexpiPropertyDataService).inSingletonScope();
    bind(PropertyViewWidgetProvider).to(DexpiPropertyViewWidgetProvider).inSingletonScope();

    // This is blueprintjs table widget
    bind(DexpiPropertyViewBlueprintWidget).toDynamicValue(ctx =>
        createDexpiPropertyViewBlueprintWidget(ctx.container)
    );

    // This is alternative property view with TreeWidget as backing widget
    bind(DexpiPropertyViewTreeWidget).toDynamicValue(ctx =>
        createDexpiPropertyViewTreeWidget(ctx.container)
    );
}

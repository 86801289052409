import { WebSocketConnectionProvider } from '@theia/core/lib/browser';
import { ContainerModule, interfaces } from 'inversify';
import { OpcNavigationService, OpcNavigationServicePath, OpcNavigationServiceProxyFactory } from '../../common/opc-navigation-protocol';
import { RemoteOpcNavigationServiceProvider } from '../../common/remote-opc-navigation-service-provider';

export default new ContainerModule(bind => {
    bindOpcNavigationFrontendService(bind);
});

export function bindOpcNavigationFrontendService(bind: interfaces.Bind) {
    bind(OpcNavigationService).toDynamicValue(context => 
        WebSocketConnectionProvider.createProxy(context.container, OpcNavigationServicePath, new OpcNavigationServiceProxyFactory())
    );
    bind(RemoteOpcNavigationServiceProvider).toSelf().inSingletonScope();

}

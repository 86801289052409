import { Callout, Intent, Tab, Tabs } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import { FileDialogService } from "@theia/filesystem/lib/browser";
import React from "react";
import { OfflinePanel } from "./OfflinePanel";
import { OnlinePanel } from "./OnlinePanel";

interface Props {
    status: boolean;
    subscriptionId: string | undefined;
    uri: string | undefined;
    expirationDate?: Date;
    machineId: string | undefined;
    onOnlineLicenseCheck: (subscriptionId: string) => Promise<void>,
    onOfflineLicenseCheck: (subscriptionId: string, file: string) => Promise<void>,
    fileDialogService: FileDialogService,
    openHelp: () => void,
}

export function LicenseView({ subscriptionId, expirationDate, onOfflineLicenseCheck, onOnlineLicenseCheck, machineId, fileDialogService, status, openHelp}: Props): JSX.Element {
    const onlinePanel = (
        <OnlinePanel
            subscriptionId={subscriptionId}
            subscriptionIdExpirationDate={expirationDate}
            onlineLicenseCheck={onOnlineLicenseCheck}
        />
    );

    const offlinePanel = (
        <OfflinePanel 
            subscriptionId={subscriptionId}
            subscriptionIdExpirationDate={expirationDate}
            offlineLicenseCheck={onOfflineLicenseCheck}
            fileDialogService={fileDialogService}
            machineId={machineId}
        />
    );

    return <div style={{padding:'10px'}}>
        <div style={{display: 'inline-block', paddingBottom: '10px'}}>
            <Callout intent={status ? Intent.SUCCESS : Intent.DANGER}>
                {status ? 'License activated' : 'License not activated'}
            </Callout>
        </div>
        <Tabs id='license-tabs'>
            <Tab id='online-license-tab' title='Online license check' panel={onlinePanel}/>
            <Tab id='offline-license-tab' title='Offline license check' panel={offlinePanel}/>
        </Tabs>
        <p style={{paddingTop: '10px'}}>See further instructions at <a href='#' onClick={openHelp}>Help/Getting Started</a>.</p>
    </div>;
}
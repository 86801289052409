import { LabelProviderContribution, TreeNode } from "@theia/core/lib/browser";
import { injectable } from "inversify";
import { DexpiPropertiesCategoryNode, DexpiPropertiesItemNode } from "./dexpi-property-view-tree-items";

const DEFAULT_INFO_ICON = 'fa fa-info-circle';

@injectable()
export class DexpiPropertiesLabelProvider implements LabelProviderContribution {

    canHandle(element: TreeNode): number {
        return (DexpiPropertiesCategoryNode.is(element) || DexpiPropertiesItemNode.is(element)) ? 75 : 0;
    }

    getIcon(node: DexpiPropertiesCategoryNode | DexpiPropertiesItemNode): string {
        if (DexpiPropertiesCategoryNode.is(node)) {
            return node.icon ?? DEFAULT_INFO_ICON;
        }
        return node.icon ?? '';
    }

    getName(node: DexpiPropertiesCategoryNode | DexpiPropertiesItemNode): string {
        return node.name;
    }

    getLongName(node: DexpiPropertiesCategoryNode | DexpiPropertiesItemNode): string {
        if (DexpiPropertiesItemNode.is(node)) {
            return node.property;
        }
        return this.getName(node);
    }
}
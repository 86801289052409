import { Emitter, Event, ILogger } from "@theia/core";
import { inject, injectable, postConstruct } from "inversify";
import { LicenseClient, LicenseClientOptions, LicenseServiceServer, LicenseStatus } from "../common/license-service";


@injectable()
export class LicenseClientImpl implements LicenseClient {

    @inject(ILogger) protected readonly logger: ILogger;
    @inject(LicenseServiceServer) protected readonly licenseService: LicenseServiceServer;
    protected readonly onLicenseStatusChangedEmitter = new Emitter<LicenseStatus>();
    onLicenseStatusChanged: Event<LicenseStatus> = this.onLicenseStatusChangedEmitter.event;

    @postConstruct()
    protected init(): void {
        this.logger.debug('initializing LicenseServiceClientClientImpl');
        const emitter = this.onLicenseStatusChangedEmitter;
        this.licenseService.setClient({
            notifyDidChangeLicenseStatus(status: LicenseStatus) {
                emitter.fire(status);
            }
        });
    }

    licenseStatus(): Promise<LicenseStatus> {
        return this.licenseService.licenseStatus();
    }

    checkLicense(subscriptionId: string): Promise<LicenseStatus> {
        console.log('check license with sid ' + subscriptionId);
        return this.licenseService.checkLicense(subscriptionId);
    }

    validateLicense(subscriptionId: string, licensePath: string): Promise<LicenseStatus> {
        console.log('validate license with sid ' + subscriptionId + ' and path ' + licensePath);
        return this.licenseService.validateLicense(subscriptionId, licensePath);
    }

    getMachineId(): Promise<string> {
        return this.licenseService.getMachineId();
    }

    updateLicenseClientOptions(options: LicenseClientOptions): Promise<void> {
        return this.licenseService.updateLicenseClientOptions(options);
    }
}
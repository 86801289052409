import { Button, ControlGroup, FormGroup, InputGroup } from '@blueprintjs/core';
import React, { ChangeEvent, useEffect, useState } from 'react';

export interface SubscriptionIdInputProps {
    subscriptionId?: string;
    handleSubmit: (subscriptionId: string) => Promise<void>;
}

export const SubscriptionIdInput = ({ subscriptionId, handleSubmit }: SubscriptionIdInputProps) => {

    const [subscriptionIdField, setSubscriptionIdField] = useState('');
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (subscriptionId) {
            setSubscriptionIdField(subscriptionId);
        }
    }, [subscriptionId]);

    const handleOnChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => setSubscriptionIdField(currentTarget.value);
    
    const handleClick = () => {
        setSubmitting(true);
        handleSubmit(subscriptionIdField)
            .then(() => {
                console.log('sid submitted');
                setSubmitting(false);
            })
            .catch((error) => {
                console.error('error occured during sid submit', error);
                setSubmitting(false);
            });
    };

    const inputDisabled = submitting;
    const buttonDisabled = submitting || subscriptionIdField == '';

    return (
        <FormGroup
            label="Enter subscription id:"
            helperText="Subscription id can be found from email."
            labelFor="subsription-id-input"
        >
        <ControlGroup id="subscription-id-input" fill={true}>
            <InputGroup onChange={handleOnChange} placeholder={"Subscription id"} value={subscriptionIdField} disabled={inputDisabled} />
            <Button loading={submitting} disabled={buttonDisabled} icon={'confirm'} onClick={handleClick}>Check</Button>
        </ControlGroup>
    </FormGroup>
    );
}
import { ILogger, JsonRpcProxy, MessageService, Progress } from "@theia/core";
import URI from "@theia/core/lib/common/uri";
import { WorkspaceService } from "@theia/workspace/lib/browser";
import { inject, injectable, postConstruct } from "inversify";
import { Disposable } from "vscode-jsonrpc";
import { OpcIndexingStatus, OpcNavigationService, OpcResult } from "./opc-navigation-protocol";

@injectable()
export class RemoteOpcNavigationServiceProvider implements Disposable {

    private progressMessages: { [id: string]: Progress} = {};

    @inject(ILogger) protected readonly logger: ILogger;
    @inject(OpcNavigationService)
    protected readonly server: JsonRpcProxy<OpcNavigationService>;

    @inject(WorkspaceService)
    protected readonly workspaceService: WorkspaceService;

    @inject(MessageService)
    protected readonly messageService: MessageService;
    
    @postConstruct()
    protected init(): void {
        this.server.setClient({
            notifyDidChangeStatus: (status: OpcIndexingStatus) => {
                this.logger.debug("Received status message " + status);
                const progressMessage = this.progressMessages[status.folderUri];
                if (!progressMessage)
                    return;
                progressMessage.report({work: {done: status.progress, total: 1}});
                if (status.progress === 1) {
                    progressMessage.cancel();
                    delete this.progressMessages[status.folderUri];
                }
            }
        });

        if (this.workspaceService.workspace) {
            this.createIndex(this.workspaceService.workspace.resource.toString());
        }        
    }

    async findConnectedOpc(id: string, folderUri: string): Promise<OpcResult> {
        const hasIndex = await this.server.hasIndex(folderUri);
        if (hasIndex) {
            return this.server.findConnectedOpc(id, folderUri)
                .then(result => result)
                .catch((err: Error) => {
                    this.messageService.error(err.message);
                    return Promise.reject(err.message);
                });
        }

        let progress = await this.server.getIndexingProgress(folderUri);
        if (!progress) {
            this.server.createIndex(folderUri).catch((err:Error) => {});
            progress = 0;
        }

        const prev = this.progressMessages[folderUri];
        if (!prev) {
            const folderName = new URI(folderUri).path.base;
            const progressMessage = await this.messageService.showProgress({
                    text: 'Indexing OPC connections of folder ' + folderName + '. OPCs can be navigated once the indexing is finished.',
                    actions: ['Close'],
            });
            progressMessage.report({work: {done: progress, total: 1}});
            progressMessage.result.then((result) => {
                if (result === 'Close') {
                    delete this.progressMessages[folderUri];
                }
            });
            this.progressMessages[folderUri] = progressMessage;
        }

        return Promise.reject('Indexing not completed');
    }

    async createIndex(folderUri: string): Promise<void> {
        this.server.createIndex(folderUri).catch((err) => {});
    }

    dispose(): void {
    }
}

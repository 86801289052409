import { Code } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import { environment, isOSX } from '@theia/core/lib/common';
import { GettingStartedWidget } from '@theia/getting-started/lib/browser/getting-started-widget';
import { injectable } from 'inversify';
import * as React from 'react';
import { OpenLicenseViewCommand } from "../license/license-view-contribution";

import { ToggleConnectivityModeCommand } from "../xml-viewer/xml-viewer-contribution";

@injectable()
export class MBGettingStartedWidget extends GettingStartedWidget {

    /**
     * Render the content of the widget.
     */
    protected render(): React.ReactNode {
        return <div className='gs-container'>
            {this.renderHeader()}
            <hr className='gs-hr' />
            {environment.electron.is() && <div className='flex-grid'>
                <div className='col'>
                    {this.renderOpen()}
                </div>
            </div>}
            {environment.electron.is() && <div className='flex-grid'>
                <div className='col'>
                    {this.renderRecentWorkspaces()}
                </div>
            </div>}
            {/*<div className='flex-grid'>
                <div className='col'>
                    {this.renderSettings()}
                </div>
            </div> */}
            {environment.electron.is() && <div className='flex-grid'>
                <div className='col'>
                    {this.renderLicenseInfo()}
                </div>
            </div>}
            <div className='flex-grid'>
                <div className='col'>
                    {this.renderHelp()}
                </div>
            </div>
            <div className='flex-grid'>
                <div className='col'>
                    {this.renderVersion()}
                </div>
            </div>
        </div>;
    }

    /**
     * Render the help section.
     */
    protected renderHelp(): React.ReactNode {
        const requireSingleOpen = isOSX || !environment.electron.is();
        const openFolder = requireSingleOpen 
            ? <a href='#' onClick={this.doOpen}>File/Open...</a>
            : <a href='#' onClick={this.doOpenFolder}>File/Open Folder...</a>;

        return <div className='gs-section'>
            <h3 className='gs-section-header'>
                <i className='fa fa-question-circle'></i>
                Help
            </h3>

            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Opening a diagram in the viewer</h4>
            {environment.electron.is() && <p style={{margin:'0px'}}>Go to {openFolder} to open a new folder in the File explorer.</p>}
            <p style={{margin:'0px'}}>In the File explorer, navigate to the XML file and click it. The diagram will be opened in the viewer.</p>
            {environment.electron.is() && <p><b>Note:</b>  To view diagrams, a license must first be activated. See below section "License activation" for further instructions.</p>}

            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Navigating the diagram</h4>
            <p style={{margin:'0px'}}>Zoom the diagram by scrolling the mouse wheel or by using the <Code>+/-</Code> keys.</p>
            <p style={{margin:'0px'}}>Move around the diagram by holding down the middle mouse button or by using the arrow keys.</p>
            <p style={{margin:'0px'}}>Recenter the view by pressing the <Code>1</Code> key.</p>

            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Connectivity mode</h4>
            <p style={{margin:'0px'}}>Toggle connectivity mode on and off with <a href='#' onClick={this.doToggleConnectivityMode}>View/Toggle connectivity mode</a> or <Code>Ctrl+M</Code> key.</p>
            <p style={{margin:'0px'}}>The colors used in the connectivity mode are:</p>
            <ul>
                <li>Blue: Pipeline connectivity</li>
                <li>Red: Instrumentation connectivity</li>
                <li>Green: Parent-child element relationship (for example equipment-nozzle relationship)</li>
                <li>Orange: Actuator association</li>
            </ul>

            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Color mode</h4>
            <p style={{margin:'0px'}}>Change color mode from View/Change color mode.</p>
            <p style={{margin:'0px'}}>The color options are:</p>
            <ul>
                <li>Black: Black background and white components and texts</li>
                <li>Default: Default colors from the diagram</li>
                <li>White: White background and black components and texts</li>
            </ul>
            
            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Property View</h4>
            <p style={{margin:'0px'}}>Select elements in the viewer by clicking them with the left mouse button. </p>
            <p style={{margin:'0px'}}>Property view will show the properties (such as ID, ComponentClass, ComponentName) and GenericAttributes of the selected element.</p>
            <p style={{margin:'0px'}}>If the selected element belongs to a PipingNetworkSegment, the attributes of the related PipingNetworkSegment or PipingNetworkSystem can also be shown.</p>
            <p style={{margin:'0px'}}>Multiple elements can be selected by holding down the left mouse button and drawing a rectangle around the elements.</p>
            <p style={{margin:'0px'}}>The selected items will be listed under Property view. Click on the group name to select all the components of the group or the component name to select a individual component.</p>
            <p style={{margin:'0px'}}>You can copy cell values from the property table by selecting the cells and copying with the right-click context menu.</p>

            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Off-page connector (OPC) navigation</h4>
            <p style={{margin:'0px'}}>Navigate between diagrams of the same folder by clicking an OPC element (PipeConnectorSymbol or SignalConnectorSymbol) on the diagram.</p>
            <p style={{margin:'0px'}}>A button with text "Jump to Connected" should appear above the element. Click on it to navigate to the connected OPC on another diagram.</p>
            <p style={{margin:'0px'}}>If no corresponding OPC element can be found from the diagrams inside the same folder, an error notification will be displayed instead.</p>
            <p style={{margin:'0px'}}><b>Note:</b>  If the folder contains a large amount of diagrams, calculating the connectivity for the folder takes some time.</p>
            <p style={{margin:'0px'}}>If OPC navigation is attempted during this time, message indicating the current progress of the calculation is displayed instead. Wait until the calculation is finished and then try again.</p>

        </div>;
    }

    /**
     * Render license info
     */
    protected renderLicenseInfo(): React.ReactNode {
        return <div className='gs-section'>
            <h3 className='gs-section-header'>
                <i className='fa fa-lock'></i>
                License activation
            </h3>
            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Online license activation (for computers with access to internet)</h4>
            <ol>
                <li>Open the License Check view from the <a href='#' onClick={this.doOpenLicenseView}>View/License view</a> menu.</li>
                <li><b>Select the “Online license check” option</b>  and provide your Subscription ID into the “Enter subscription ID” box. The Subscription ID can be found on your purchase confirmation mail.</li>
                <li>Press the “Check” button. Your license should be now activated, and you can start using MB DEXPI Viewer!</li>
            </ol>
            <p><b>NOTE:</b>  If you purchased multiple number of licenses, you still have only one Subscription ID. Just repeat steps 1 to 4, providing the same Subscription ID on each of the computers where you will be using MB DEXPI Viewer.</p>
            <p><b>Important: the Online license activation may fail due to different company IT settings.</b>  If your Online license installation fails, please use the Offline license activation steps.</p>
            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Offline license activation (for computers without access to the internet)</h4>
            <ol>
                <li>Open the License Check view from the <a href='#' onClick={this.doOpenLicenseView}>View/License view</a> menu.</li>
                <li><b>Select the “Offline license check” option</b>  and copy the id from “Your machine id” field of the Offline license check interface.</li>
                <li>Send your machine id to <a href = 'mailto:support@model-broker.com'>support@model-broker.com</a>. We will provide a license file that can be used to activate your Offline license. We will send you the license file as soon as possible. Our response time should not be longer than 24 hours since we get your mail.</li>
                <li>Once you get the license file, provide your Subscription ID into the “Enter subscription ID” box. The Subscription ID can be found on your purchase confirmation mail.</li>
                <li>Use the “Select a license file” field to provide your license file.</li>
                <li>Press the “Check” button. Your license should be now activated, and you can start using MB DEXPI Viewer.</li>
            </ol>
            <p><b>NOTE:</b>  If you purchased multiple number of licenses, you still have only one Subscription ID. Just repeat steps 1 to 4, providing the same Subscription ID on each of the computers where you will be using MB DEXPI Viewer. However, you must use different license files as these files are different for each computer. This is because each computer has different ids.</p>
            <h4 style={{marginTop: '10px', marginBottom: '10px'}}>Description of license types</h4>
            <p>MB has two types of licenses available: Online license and Offline license.</p>
            <ul>
                <li><b>The Online license</b>  is the most common license type. It can be used in computers with persistent access to the internet, as it periodically verifies the license validity using an internet connection.</li>
                <li>Alternatively, the <b>Offline license</b>  can be used in computers with no access to the internet. The license validity is verified using a machine id. This id is generated by the MB DEXPI Viewer software.</li>
            </ul>
            <p>Both license types have a fixed 1-year validity time, starting from the purchase date. More information can be found <a href='https://store.semantum.fi/pages/model-broker-dexpi-viewer-license-agreement'>here</a>.</p>
        </div>
    }

    protected doOpenLicenseView = () => this.commandRegistry.executeCommand(OpenLicenseViewCommand.id);    
    protected doToggleConnectivityMode = () => this.commandRegistry.executeCommand(ToggleConnectivityModeCommand.id);  
}

import { WidgetFactory } from '@theia/core/lib/browser';
import { GettingStartedWidget } from '@theia/getting-started/lib/browser/getting-started-widget';
import { ContainerModule, interfaces } from 'inversify';
import { MBGettingStartedWidget } from './mb-getting-started-widget';

export default new ContainerModule(bind => {
    bindMBGettingStartedContribution(bind);
});

export function bindMBGettingStartedContribution(bind: interfaces.Bind) {
    bind(MBGettingStartedWidget).toSelf();
    bind(WidgetFactory).toDynamicValue(context => ({
        id: GettingStartedWidget.ID, 
        createWidget: () => context.container.get<MBGettingStartedWidget>(MBGettingStartedWidget),
    })).inSingletonScope();
}

import { LicenseStatus as JSClientLicenseStatus } from "@semantum/js-license-client/dist/Validator";
import { Event, JsonRpcProxyFactory, JsonRpcServer } from "@theia/core";

export const licenseServicePath = '/services/license';
export const LicenseServiceServer = Symbol('LicenseServiceServer');

export interface LicenseServiceServer extends JsonRpcServer<LicenseServiceClient> {

    licenseStatus(): Promise<LicenseStatus>;

    checkLicense(subscriptionId: string): Promise<LicenseStatus>

    validateLicense(subscriptionId: string, licensePath: string): Promise<LicenseStatus>;

    getMachineId(): Promise<string>;

    updateLicenseClientOptions(options: LicenseClientOptions): Promise<void>;

}

export interface LicenseClientOptions {
    proxyAddress?: string;
}

export interface LicenseServiceClient {

    notifyDidChangeLicenseStatus(status: LicenseStatus): void;
}

export interface LicenseClient {

    onLicenseStatusChanged: Event<LicenseStatus>;

    licenseStatus(): Promise<LicenseStatus>;

    checkLicense(subscriptionId: string): Promise<LicenseStatus>

    validateLicense(subscriptionId: string, licensePath: string): Promise<LicenseStatus>;

    getMachineId(): Promise<string>;

    updateLicenseClientOptions(options: LicenseClientOptions): Promise<void>;
}

export class LicenseServiceProxyFactory<T extends object> extends JsonRpcProxyFactory<T> {
}

export interface LicenseStatus {
    subscriptionId: string;
    active: boolean;
    status: string;
    expirationDate?: Date;
    licenseFilePath?: string;
}


export namespace LicenseStatus {

    export function emptyStatus(): LicenseStatus {
        return {
            subscriptionId: '',
            active: false,
            status: '',
        };
    }

    export function convert(subscriptionId: string, status: JSClientLicenseStatus, expirationDate?: Date, licenseFilePath?: string): LicenseStatus {
        return {
            subscriptionId,
            active: JSClientLicenseStatus.VALID === status,
            status,
            expirationDate,
            licenseFilePath
        };
    }

    export function error(subscriptionId: string, error: Error, licenseFilePath?: string): LicenseStatus {
        return {
            subscriptionId,
            active: false,
            status: error.message ? error.message : '',
            licenseFilePath
        }
    }
}
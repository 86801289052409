import { PreferenceConfigurations } from "@theia/core/lib/browser/preferences/preference-configurations";
import { injectable } from "inversify";

const preferencesDirName = ".mb-dexpi-viewer";

@injectable()
export class MBPreferenceConfigurations extends PreferenceConfigurations {
    getPaths(): string[] {
        return [preferencesDirName, '.theia', '.vscode'];
    }
}
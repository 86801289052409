import { JsonRpcProxyFactory, JsonRpcServer } from "@theia/core";

export const OpcNavigationService = Symbol('OpcNavigationService');
export const OpcNavigationServicePath = '/services/opcNavigation';

export interface OpcResult {
    id: string;
    fileUri: string;
}

export interface OpcIndexingStatus {
    folderUri: string;
    progress: number; // from 0 to 1
}

export interface OpcNavigationService extends JsonRpcServer<OpcNavigationServiceClient>{

    findConnectedOpc(id: string, folderUri: string): Promise<OpcResult>
    hasIndex(folderUri: string): Promise<boolean>
    createIndex(folderUri: string): Promise<void>
    getIndexingProgress(folderUri: string): Promise<number|undefined>

}

export interface OpcNavigationServiceClient {
    notifyDidChangeStatus(status: OpcIndexingStatus): void;
}

export class OpcNavigationServiceProxyFactory<T extends object> extends JsonRpcProxyFactory<T> {
    
}
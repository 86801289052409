import React, { useState } from "react";

import { MultiSelectionTable } from './MultiSelectionTable';
import { PropertyTable } from './PropertyTable';

interface Props {
    selection: Element[];
    onElementSelection: (elements: Element[]) => void;
}

export function SelectionView(props: Props): JSX.Element {
    const [selection, setSelection] = useState<Element[]>([])

    React.useEffect(() => {
        setSelection(props.selection);
    }, [props.selection])

    function content(): JSX.Element {
        if (selection.length === 0) {
            return <p>Nothing selected</p>;
        }
        else if (selection.length === 1) {
            return <>
                <PropertyTable element={selection[0]} />
            </>
        }
        else {
            return <div style={{ width: "100%", height: "100%", overflowY: 'auto' }}>
                <MultiSelectionTable elements={selection} onChangeSelection={(elements) => {
                    setSelection(elements)
                    props.onElementSelection(elements)
                }} />
            </div>;
        }
    }

    return (
        <div
            tabIndex={0}
            style={{ width: '100%', height: '100%', paddingLeft:'10px', paddingTop: '10px'}}
        >
            {content()}
        </div>
    );
}
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import { SelectionService } from "@theia/core";
import { Message, ReactWidget } from "@theia/core/lib/browser";
import { PropertyDataService } from "@theia/property-view/lib/browser/property-data-service";
import { PropertyViewContentWidget } from "@theia/property-view/lib/browser/property-view-content-widget";
import { inject, injectable } from "inversify";
import React from "react";
import { DexpiElement } from "../../common/dexpi-element";
import { DexpiElementSelection } from "../../common/dexpi-element-selection";
import { SelectionView } from "./react/SelectionView";

@injectable()
export class DexpiPropertyViewBlueprintWidget extends ReactWidget implements PropertyViewContentWidget {
    protected currentSelection: Object | undefined;
    protected dexpiElements: DexpiElement[];

    private resizeObserver?: ResizeObserver;
    private height: number;

    constructor(
        @inject(SelectionService) protected readonly selectionService: SelectionService,
    ) {
        super();
        this.id = 'DexpiPropertyViewBlueprintWidget';
    }

    protected onAfterAttach(msg: Message): void {
        super.onAfterAttach(msg);
        const propertyViewParent = document.querySelector('#property-view');
        if (propertyViewParent) {
            this.resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
                if (entries.length > 0) {
                    const entry = entries[0];
                    this.height = entry.contentRect.height;
                    this.update();
                }
            });
            this.resizeObserver.observe(propertyViewParent);
        }
    }

    protected onBeforeDetach(msg: Message): void {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        super.onBeforeDetach(msg)
    }

    updatePropertyViewContent(propertyDataService?: PropertyDataService, selection?: Object): void {
        if (this.updateNeeded(selection)) {
            this.currentSelection = selection;
            if (propertyDataService) {
                propertyDataService.providePropertyData(selection).then((elements: DexpiElement[]) => {
                    this.dexpiElements = elements;
                    this.update();
                });
            }
        }
    }

    protected updateNeeded(selection: Object | undefined): boolean {
        return this.currentSelection !== selection;
    }

    render() {
        return (
            <div style={{overflow: 'auto', height: this.height}}>
                <SelectionView selection={this.dexpiElements} onElementSelection={(elements) => this.onElementSelection(elements)}/>
            </div>
        )
    }

    protected onElementSelection(selectedElements: Element[]): void {
        this.updateGlobalSelection(DexpiElementSelection.create(selectedElements as HTMLElement[], this.id));
    }

    protected updateGlobalSelection(selection: DexpiElementSelection[]): void {
        this.selectionService.selection = selection;
    }
}
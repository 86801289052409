import React, { FunctionComponent } from "react";
import styled from 'styled-components';

const AboutFrameOuter = styled.div`
width: min(80em,80vw);
height: min(60em,75vh);
overflow: auto;
`;

const AboutFrameInner = styled.div`
position: relative;
`;

const AboutFrame: FunctionComponent<{}> = props => <AboutFrameOuter><AboutFrameInner>{props.children}</AboutFrameInner></AboutFrameOuter>;

const VersionBox = styled.div`
position: absolute;
right: 0;
`;

const IndList = styled.div`
list-style-type: none;
padding-left: 1em;
`;

export default (props: {}) => (
    <AboutFrame>
        <VersionBox>
            Version 1.0
            <br/>
            5th of May 2021
        </VersionBox>

        <h1>Model Broker Dexpi Viewer License Agreement</h1>

        <p>IMPORTANT - PLEASE READ CAREFULLY BEFORE INSTALLING OR USING:</p>
        <p>THIS SOFTWARE LICENSE AGREEMENT (“AGREEMENT”) IS A LEGALLY BINDING CONTRACT BETWEEN THE END USER (“LICENSEE“) AND SEMANTUM AND ITS DESIGNATED SUBSIDIARIES (COLLECTIVELY, “SEMANTUM”) FOR LICENSEE TO INSTALL OR USE SEMANTUM MODEL BROKER DEXPI VIEWER SOFTWARE PRODUCT. BY INSTALLING OR OTHERWISE USING THE SOFTWARE PRODUCT, LICENSEE AGREES TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF LICENSEE DOES NOT AGREE, IT MAY NOT INSTALL NOR USE THE SOFTWARE PRODUCT AND HAS TO PROMPTLY RETURN IT TO THE PLACE OF PURCHASE FOR A REFUND, IF APPLICABLE. </p>
        <p>IF THE PURCHASING COMPANY IS NOT THE END USER OF THE SOFTWARE PRODUCT, THE PURCHASING COMPANY IS REQUIRED TO PRESENT THE TERMS AND CONDITIONS OF THIS AGREEMENT TO THE ACTUAL END USER AND OBTAIN ITS CONSENT TO ABIDE BY THEM BEFORE IT STARTS TO USE THE SOFTWARE PRODUCT, BECOMING THE ACTUAL LICENSEE OF THE SOFTWARE PRODUCT AS A RESULT OF SUCH CONSENT.</p>
        <p>THE TERMS OF THIS AGREEMENT MAY ONLY BE MODIFIED IN WRITING, SIGNED BY A DULY AUTHORIZED REPRESENTATIVE OF SEMANTUM AND LICENSEE.</p>

        <h2>1. Scope</h2>
        <p>1.1 This Agreement applies to the Model Broker Dexpi Viewer software products (the “Software Product”) of which Semantum or any of its licensors  owns the intellectual property rights. The Software Product consists of:</p>
        
        <IndList>
            <li>a) Standard Software Product: The software products listed in “General Specifications” of Semantum.</li>
            <li>b) Customized Software Product: The software products developed/made by Semantum based on individually agreed specifications, which will be used with or in addition to the function of the Standard Software Product.</li>
        </IndList>

        <p>1.2 The Software Product includes, without limitation, computer programs, key codes (software license files), manuals and other associated documents, databases, fonts, input data, and any images, photographs, animations, video, voice, music, text, and applets (software linked to text and icons) embedded in the software.</p>
        <p>1.3 Unless otherwise provided by Semantum, this Agreement applies to the updates and upgrades of the Software Product.</p>

        <h2>2. Grant of License</h2>
        <p>2.1 Subject to the terms and conditions of this Agreement, Semantum hereby grants to Licensee a non-exclusive
        and non-transferable right during the subscription period to use the Software Product on the hardware separately specified by Semantum and solely for your Licensee’s internal operation use, in consideration of full payment by Licensee of the license fee separately agreed upon. Use of the Software Product shall be subject to the terms and conditions of General Specifications, instruction manuals and other associated documents in addition to this Agreement.</p>
        <p>2.2 Unless otherwise agreed or provided by Semantum in writing, the following acts are prohibited:</p>

        <IndList>
            <li>a) to reproduce the Software Product;</li>
            <li>b) to sell, lease, distribute, transfer, pledge, sublicense, make available via the network or otherwise convey the Software or the license granted herein to any other person or entity;</li>
            <li>c) to use the Software Product on any unauthorized hardware via the network;</li>
            <li>d) to cause, permit or attempt to dump, disassemble, decompile, reverse-engineer, or otherwise translate or reproduce the Software Product into source code or other human readable format, or to revise or translate the Software Product into other language and change it to other formats than that in which Semantum provided;</li>
            <li>e) to cause, permit or attempt to remove any copy protection used or provided in the Software Product;</li>
            <li>f) to remove any copyright notice, trademark notice, logo or other proprietary notices or identification shown in the Software Product; or</li>
            <li>g) develop or have developed derivative software or other computer programs which are based on the Software Product unless otherwise permitted by Semantum in writing.</li>
        </IndList>

        <p>2.3 Any and all technology, algorithms, know-how and process contained in or applicable on the Software Product are the intellectual property or trade secret of Semantum or any of its licensors. Copyright, trademark and any other intellectual property rights in and ownership of the Software Product shall be retained by Semantum or any of its licensors and none of the rights will be transferred to Licensee hereunder.</p>
        <p>2.4 Licensee agrees to maintain the aforementioned intellectual property and trade secrets of Semantum or any
        of its or any of its licensors and key codes (software license files) in strict confidence, not to disclose it to any party other
        than Licensee’s employees, officers, directors or similar staff who have a legitimate need to know to use the
        Software Product and agreed in writing to abide by the obligations hereunder.</p>
        <p>2.5 Upon expiration of subscription period or termination of this Agreement, the Software Product and its copies , including extracts,  retained in Licensee’s hardware or any other media shall be deleted irretrievably. If Licensee disposes of media in which the Software Product or its copy is stored, the contents shall be irretrievably deleted.</p>
        <p>2.6 The Software Product may contain software which Semantum is granted a right to sublicense or distribute by third party suppliers (“Third Party Software”). If suppliers of the Third Party Software (“Supplier”) provide special terms and conditions for the Third Party Software which differ from this Agreement, the special terms and conditions shall prevail over this Agreement. Some Third Party Software may be licensed to Licensee directly by Supplier.</p>
        <p>2.7 The Software Product contains open source software (“OSS”), for which separately provided special terms
        and conditions shall take precedence over this Agreement.</p>

        <h2>3．Restriction on use</h2>
        <p>3.1　Unless otherwise agreed in writing between Licensee and Semantum, the Software Product is not intended, designed, produced or licensed for aircraft operation or control, ship navigation, or ground facility or device for support of the aforesaid operation or control, nor for planning, construction, maintenance or operation of any nuclear related facility.</p>
        <p>3.2　If the Software Product is used for the above mentioned purposes, neither Semantum nor Supplier assumes liability for any claim or damage arising from the said use and Licensee shall indemnify and hold Semantum, Supplier, their affiliates, subcontractors, officers, directors, employees and agents harmless from any liability or damage whatsoever, including any court costs and attorney’s fees, arising out of or related to the said use.</p>

        <h2>4. Warranty / Maintenance Services</h2>
        <p>4.1　The Software Product is provided “AS IS”. Workaround for defects or vulnerabilities, patch software, products information and/or other technical inquiry services will be available subject to a maintenance contract to be purchased from Semantum sales/service offices or their agents (any maintenance service for the Third Party Software shall be subject to the terms and conditions provided by the Supplier).</p>
        <p>4.2  TO THE MAXIMUM EXTENT PERMITTED BY LAW, SEMANTUM DISCLAIMS ALL OTHER WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT OF THIRD PARTY RIGHTS. SEMANTUM DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SOFTWARE PRODUCT WILL MEET ANY REQUIREMENTS OR NEEDS LICENSEE MAY HAVE, THAT THE SOFTWARE PRODUCT WILL OPERATE ERROR FREE, OR IN AN UNINTERRUPTED FASHION, THAT ANY DEFECTS OR ERRORS IN THE SOFTWARE PRODUCT WILL BE CORRECTED, THAT THE SOFTWARE PRODUCT HAS NO INCONSISTENCY OR INTERFERENCE WITH OTHER SOFTWARE, THAT THE SOFTWARE PRODUCT OR THE RESULTS ARISING THEREFROM IS PRECISE, RELIABLE OR UP-TO-DATE, THAT THE SOFTWARE PRODUCT IS COMPATIBLE WITH ANY PARTICULAR SOFTWARE REQUIRED TO RUN THE SOFTWARE PRODUCT, OR THAT THE SOFTWARE PRODUCT IS FREE OF VULNERABILITY(INCLUDING BUT NOT LIMITED TO VULNERABILITY TO INTRUSION OR ATTACK CAUSED BY COMPUTER VIRUS OR UNAUTHORIZED ACCESS OR THE LIKE). SEMANTUM SHALL NOT BE LIABLE FOR ANY DAMAGES CAUSED BY THE ENVIRONMENT (INCLUDING WITHOUT LIMITATION SOFTWARE AND HARDWARE) OF THE LICENSEE. SOME JURISDICTIONS DO NOT ALLOW THE WAIVER OR EXCLUSION OF IMPLIED WARRANTIES SO THEY MAY NOT APPLY TO LICENSEE.</p>

        <h2>5. Infringement</h2>
        <p>５.1 If Licensee is warned or receives a claim by a third party that the Software Product in its original form infringes any third party’s patent (which is issued at the time of delivery of the Software Product), trade mark, copyright or other intellectual property rights (”Claim”), Licensee shall promptly notify Semantum thereof in writing.</p>
        <p>5.2 If the infringement is attributable to Semantum, Semantum will defend Licensee from the Claim at Semantum’s expense and indemnify Licensee from the damages finally granted by the court or otherwise settled by Semantum out of court. The foregoing obligation and indemnity of Semantum shall be subject to </p>
        
        <IndList>
            <li>i) Licensee promptly notifying Semantum of the Claim in writing as provided above, </li>
            <li>ii) Licensee granting to Semantum and its designees the full authority to control the defense and settlement of such Claim and</li>
            <li>iii) Licensee to give every and all necessary information and assistance to Semantum upon Semantum’s request.</li>
        </IndList>

        <p>5.3 If Semantum believes that a Claim may be made or threatened, Semantum may, at its option and its expense, either a) procure for Licensee the right to continue using the Software Product, b) replace the Software Product with other software product to prevent infringement, c) modify the Software Product, in whole or in part, so that it becomes non-infringing, or d) if Semantum believes that a) through c) are not practicable, refund Licensee the amount of the book value of the Software Product as depreciated.</p>
        <p>5.4 Notwithstanding the foregoing, Semantum shall have no obligation nor liability for, and Licensee shall defend and indemnify Semantum and its suppliers from, the Claim, if the infringement is arising from a) any modification of or addition to the Software Product or related documentation not provided or approved by Semantum, b) a combination of the Software Product and other equipment, software or systems, whether or not Semantum supplied or suggested such use of combination, c) design or instruction provided by or on behalf of Licensee, d) not complying with Semantum’s suggestion, e) an act or omission of Licensee, its customers or any other persons than Semantum, except those carried out on the express instruction of Semantum, or f) any other causes not attributable to Semantum. </p>
        <p>5.5 This section states the entire liability of Semantum and its suppliers and the sole remedy of Licensee with
        respect to any claim of infringement of third party’s intellectual property rights. Notwithstanding anything
        to the contrary stated herein, with respect to the claims arising from or related to the Third Party Software
        or OSS, the special terms and conditions separately provided for such Third Party Software or OSS shall
        prevail.</p>

        <h2>6. Limitation of Liability</h2>
        <p>EXCEPT TO THE EXTENT THAT LIABILITY MAY NOT LAWFULLY BE EXCLUDED OR LIMITED BY CONTRACT, SEMANTUM AND SUPPLIERS SHALL NOT BE LIABLE TO ANY PERSON OR LEGAL ENTITY FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF MATERIALS OR PRODUCTS, LOSS OF PRODUCTION, LOSS OF CONTRACTS, LOSS OR DESTRUCTION OF DATA, LOSS OF AVAILABILITY AND THE LIKE, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, OR OTHER SIMILAR DAMAGES OF ANY KIND, ARISING OUT OF THE USE OR INABILITY TO USE OF THE SOFTWARE PRODUCT, OR ARISING OUT OF ITS GENERATED APPLICATIONS OR DATA, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER BASED IN WARRANTY (EXPRESS OR IMPLIED), CONTRACT, STRICT LIABILITY, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL OR EQUITABLE GROUNDS.  IN NO EVENT SEMANTUM AND SUPPLIER’S AGGREGATE LIABILITY FOR ANY CAUSE OF ACTION WHATSOEVER (INCLUDING LIABILITY UNDER CLAUSE 5) SHALL EXCEED THE BOOK VALUE OF THE  LICENSEE FEE PAID TO SEMANTUM FOR THE USE OF THE CONCERNED PART OF THE SOFTWARE PRODUCT.</p>
        <p>If the product delivered by Semantum is altered, modified or combined with other software or is otherwise
        made different from Semantum’s General Specifications, basic specifications, functional specifications or
        manuals without Semantum’s prior written consent, Semantum shall be exempted from its obligations and
        liabilities under this Agreement or by law.</p>

        <h2>7. Assignment</h2>
        <p>Licensee shall not assign its rights or obligations under this Agreement without prior written consent of Semantum. If Licensee novates or assigns this Agreement and the Software Product with Semantum’s consent, Licensee shall transfer all copies and whole part of the Software Product to the assignee and shall delete any and all copy of the Software Product in possession irretrievably. This Agreement shall inure to the benefit of and shall be binding on the successors of the parties.</p>

        <h2>8. Export Control</h2>
        <p>Licensee agrees to comply with the export control and related laws, regulations and orders of European Union, Japan, the United States of America, and any other applicable countries and, if Licensee exports or re-exports the Software Product, to obtain export/import permit and take all necessary procedures under Licensee’s own responsibility and at Licensee’s own expense.</p>

        <h2>9. Audit; Withholding</h2>
        <p>9.1 Semantum shall have the right to access and audit Licensee’s facilities and any of Licensee’s records, including data stored on computers, in relation to the use of the Software Product as may be reasonably necessary to verify that the requirements of this Agreement are being met.</p>
        <p>9.2 Even after the license being granted under this Agreement, should there be any change in circumstances or environment of use which was not foreseen at the time of delivery and, in Semantum’s reasonable opinion, is not appropriate for using the Software Product, or if Semantum otherwise reasonably believes it is inappropriate for Licensee to continue using the Software Product, Semantum may suspend or withhold the license provided hereunder.</p>

        <h2>10. Termination</h2>
        <p>This Agreement shall terminate upon expiration of the subscription period.  Semantum shall have the right to terminate this Agreement with immediate effect upon notice to Licensee, if
        Licensee or end users breach any of the terms and conditions hereof. Upon termination of this Agreement,
        Licensee shall, and make end users, promptly cease using the Software Product and, in accordance with
        sub-clause 2.5, return or irretrievably delete all copies of the Software Product, certifying the same in writing.
        In this case the license fee paid by Licensee for the Software Product shall not be refunded. Clauses 2.4 and
        2.5, 4.2, 6 and 11 shall survive any termination of this Agreement.</p>

        <h2>11. Governing Law; Dispute Resolution</h2>
        <p>This Agreement will be governed by and construed in accordance with the laws of Finland without any reference to another jurisdiction. Any dispute, controversy or claim arising out of or relating to this Agreement, or the breach, termination or validity thereof shall be finally settled by arbitration in accordance with the Arbitration Rules of the Finnish Central Chamber of Commerce. The seat of the arbitration shall be Helsinki, Finland and, if not otherwise agreed by the Parties, the language to be used shall be English.</p>

        <h2>12. Miscellaneous</h2>
        <p>12.1 This Agreement supersedes all prior oral and written understandings, representations and discussions between the parties concerning the subject matter hereof to the extent such understandings, representations and discussions should be discrepant or inconsistent with this Agreement.</p>
        <p>12.2 If any part of this Agreement is found void or unenforceable, it shall not affect the validity of the balance of the Agreement, which shall remain valid and enforceable according to its terms and conditions. The parties hereby agree to attempt to substitute for such invalid or unenforceable provision a valid or enforceable provision that achieves to the greatest extent possible the economic, legal and commercial objectives of the invalid or unenforceable provision.</p>
        <p>12.3 Failure by either party to insist on performance of this Agreement or to exercise a right does not prevent such party from doing so at a later time, either in relation to that default or any subsequent default.</p>
    </AboutFrame>
);
import { Code, Divider } from '@blueprintjs/core';
import { FileDialogService } from '@theia/filesystem/lib/browser';
import React, { useEffect, useState } from 'react';
import { SubscriptionExpirationInput } from './SubscriptionExpirationInput';
import { SubscriptionIdInput } from './SubscriptionIdInput';
import { SubscriptionLicenseFileInput } from './SubscriptionLicenseFileInput';

export interface OfflinePanelProps {
    subscriptionId?: string;
    fileURI?: string;
    subscriptionIdExpirationDate?: Date;

    machineId?: string;
    offlineLicenseCheck: (subscriptionId: string, file: string) => Promise<void>;
    fileDialogService: FileDialogService,
}

export const OfflinePanel = ({ subscriptionId, fileURI, machineId, subscriptionIdExpirationDate, offlineLicenseCheck, fileDialogService }: OfflinePanelProps) => {
    
    const [selectedUri, setSelectedUri] = useState<string|undefined>(undefined);

    useEffect(() => {
        setSelectedUri(fileURI);
    }, [fileURI])


    const handleSubmit = async (subscriptionId: string) =>  {
        if (selectedUri) {
            return offlineLicenseCheck(subscriptionId, selectedUri);
        }
        return Promise.reject('no selected file uri');
    };

    return (
        <>
            <SubscriptionLicenseFileInput fileURI={selectedUri} fileDialogService={fileDialogService} setSelectedURI={setSelectedUri} />
            <SubscriptionIdInput subscriptionId={subscriptionId} handleSubmit={handleSubmit} />
            <SubscriptionExpirationInput subscriptionIdExpirationDate={subscriptionIdExpirationDate} />
            <div style={{paddingTop: '10px', paddingBottom: '10px'}}>
                <Divider />
            </div>
            {machineId && <p>Your machine id is <Code>{machineId}</Code>.</p>}
        </>
    )
}
import { PropertyDataService } from "@theia/property-view/lib/browser/property-data-service";
import { injectable } from "inversify";
import { DexpiElement } from "../../common/dexpi-element";
import { DexpiElementSelection } from "../../common/dexpi-element-selection";

@injectable()
export class DexpiPropertyDataService implements PropertyDataService {
    readonly id: string = 'dexpi-xmls';
    readonly label: string = 'DexpiPropertyDataService';

    canHandleSelection(selection: Object | undefined): number {
        return this.isDexpiElementSelection(selection) ? 1 : 0;
    }

    protected isDexpiElementSelection(selection: Object | undefined): boolean {
        return !!selection && Array.isArray(selection) && DexpiElementSelection.is(selection[0]);
    }

    async providePropertyData(selection: Object | undefined): Promise<DexpiElement[]> {
        if (this.isDexpiElementSelection(selection) && Array.isArray(selection)) {
            const dexpiSelections = selection as DexpiElementSelection[];
            return dexpiSelections.map((dexpiElementSelection) => dexpiElementSelection.dexpiElement);
        }
        return [];
    }

}
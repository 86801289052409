import { CommonCommands, FrontendApplication, FrontendApplicationContribution, KeybindingContribution, KeybindingRegistry } from '@theia/core/lib/browser';
import { injectable } from "inversify";


@injectable()
export class RemovalContribution implements KeybindingContribution, FrontendApplicationContribution {

    registerKeybindings(keybindings: KeybindingRegistry): void {
        keybindings.unregisterKeybinding("ctrl+w");
        keybindings.registerKeybinding({            
            command: CommonCommands.CLOSE_TAB.id,
            keybinding: "ctrl+w"
        });
    }

    async configure(app: FrontendApplication): Promise<void> {
        //app.shell.leftPanelHandler.removeMenu('settings-menu');
    }

}
import { injectable } from "inversify";
import { FileNavigatorFilter } from "@theia/navigator/lib/browser/navigator-filter";
import { Minimatch } from 'minimatch';
import { FileStat } from "@theia/filesystem/lib/common/files";

class XMLFileNavigatorFilterPredicate implements FileNavigatorFilter.Predicate {

    private readonly delegate: FileNavigatorFilter.Predicate;

    constructor(exclusions: FileNavigatorFilter.Exclusions) {
        const patterns = Object.keys(exclusions).map(pattern => ({ pattern, enabled: exclusions[pattern] })).filter(object => object.enabled).map(object => object.pattern);
        this.delegate = FileNavigatorFilter.Predicate.and(...patterns.map(pattern => this.createDelegate(pattern)));
    }

    filter(item: { id: string, fileStat?: FileStat }): boolean {
        if(item.fileStat !== undefined && item.fileStat.isFile && !item.id.toLowerCase().endsWith(".xml")) {
            return false;
        }
        return this.delegate.filter(item);
    }

    protected createDelegate(pattern: string): FileNavigatorFilter.Predicate {
        const delegate = new Minimatch(pattern, { matchBase: true });
        return {
            filter: item => !delegate.match(item.id)
        };
    }

}

@injectable()
export class XMLFileNavigatorFilter extends FileNavigatorFilter {
    protected createFilterPredicate(exclusions: FileNavigatorFilter.Exclusions): FileNavigatorFilter.Predicate {
        return new XMLFileNavigatorFilterPredicate(this.interceptExclusions(exclusions));
    }
}
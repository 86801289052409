import { CompositeTreeNode, ExpandableTreeNode, SelectableTreeNode, TreeNode } from '@theia/core/lib/browser';

export const ROOT_ID = 'DexpiPropertiesTree';

export interface DexpiPropertiesRoot extends CompositeTreeNode {
    children: DexpiPropertiesCategoryNode[];
}
export namespace DexpiPropertiesRoot {
    export function is(node: Object | undefined): node is DexpiPropertiesRoot {
        return CompositeTreeNode.is(node) && node.id === ROOT_ID;
    }
}

export interface DexpiPropertiesCategoryNode extends ExpandableTreeNode, SelectableTreeNode {
    name: string;
    icon?: string;
    children: DexpiPropertiesItemNode[];
    parent: DexpiPropertiesRoot;
    categoryId: string;
}
export namespace DexpiPropertiesCategoryNode {
    export function is(node: TreeNode | undefined): node is DexpiPropertiesCategoryNode {
        return ExpandableTreeNode.is(node) && SelectableTreeNode.is(node) && 'categoryId' in node;
    }
}

export interface DexpiPropertiesItemNode extends SelectableTreeNode {
    name: string;
    icon?: string;
    parent: DexpiPropertiesCategoryNode;
    property: string;
}
export namespace DexpiPropertiesItemNode {
    export function is(node: TreeNode | undefined): node is DexpiPropertiesItemNode {
        return !!node && SelectableTreeNode.is(node) && 'property' in node;
    }
}

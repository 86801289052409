import { BoxSelectionEvent, BoxSelectionLayer, ClickEvent, DiagramEventHandler, KonvaLayerGroup, Viewport, ViewportContext } from "@modelbroker/diagram-viewport";
import { useContext, useEffect } from "@theia/core/shared/react";
import React from "react";
import { Layer } from "react-konva";
import { ConnectivityRenderer } from "../../proteus/ConnectivityRenderer";
import { CrossPageConnectionButton } from "../../proteus/CrossPageConnectionButton";
import { ElementCache, getElementCache } from "../../proteus/ElementCache";
import { Extent } from "../../proteus/Extent";
import { ProteusRenderer } from "../../proteus/ProteusRenderer";
import { getBackgroundColor, getExtentFromDocument, getId } from '../../proteus/ProteusXML';
import { SelectionRenderer } from "../../proteus/SelectionRenderer";

export enum SelectionOp {
    Replace,
    Add,
    Toggle
}

interface Props {
    selection: string[];
    proteusModel: Document;
    handleSelection: (ids: string[], op: SelectionOp) => void;
    onFindConnectedOPC: (opc: Element) => void;
    name: string;
    connectivityMode: boolean;
    colorMode: number;
    notificationCallback: (message: string) => void;
    zoomToSelection: boolean;
}

const EMPTY_ARRAY: string[] = [];

/**
 * Fits viewpoint so selection when it changes and is different from empty array.
 */
function SelectionFitter(props: {
    elementCache: ElementCache,
    selection: string[]
}) {
    const { selection, elementCache } = props;
    const viewportState = useContext(ViewportContext);
    useEffect(() => {
        if(selection.length === 0)
            return;
        const viewpoint = elementCache.viewpointFromIds(selection);
        if(viewpoint === undefined)
            return;
        viewportState.dispatch({
            type: 'setViewpoint',
            viewpoint
        });    
    }, [selection]);
    return null;
}

function DiagramCanvas(props: Props) {
    const { selection, handleSelection, onFindConnectedOPC, proteusModel, name, connectivityMode, colorMode, notificationCallback, zoomToSelection } = props;

    const elementCache = getElementCache(notificationCallback, proteusModel instanceof Document ? proteusModel : undefined);
    const viewpoint = selection.length === 0 ? undefined : elementCache.viewpointFromIds(selection);
    let bgColor = getBackgroundColor(props.proteusModel);
    if (colorMode === 1) {
        bgColor = 'white';
    }
    if (colorMode === 2) {
        bgColor = 'black';
    }
    
    let extent = undefined;
    if (props.proteusModel instanceof Document) {
        extent = getExtentFromDocument(props.proteusModel);
        if(extent)
            extent = extent.flipY();
    }

    const onClick = (event: ClickEvent) => {
        const [x,y] = event.position;

        const attrs = event.target?.clickEndShape?.attrs;
        const customButton = attrs?.customButton;
        if (customButton)
            switch (customButton) {
                case 'Jump to Connected':
                    if (selection.length > 0) {
                        console.log('jump to connected opc')
                        onFindConnectedOPC(elementCache.getElement(selection[0])!)
                        return true;
                    }
            }

        let id = attrs?.id;
        if (id === undefined) {
            const element = elementCache.pick({x, y});
            if (element!==undefined)
                id = getId(element);
        }
        if (id !== undefined) {
            handleSelection([id], event.ctrlKey ? SelectionOp.Add : SelectionOp.Replace);
            return true;
        }
        else {
            if (!event.ctrlKey) {
                handleSelection([], SelectionOp.Replace);
                return true;
            }
        }
        return false;
    };

    const onBoxSelection = (event: BoxSelectionEvent) => {
        const [beginX, beginY] = event.startPosition;
        const [endX, endY] = event.endPosition;
        const elements = elementCache.pickArea({begin: {x: beginX, y: beginY}, end: {x: endX, y: endY}});
        const ids = elements.map((element: Element) => getId(element)).filter((id: string) => id !== null) as string[];
        handleSelection(ids, event.ctrlKey ? SelectionOp.Add : SelectionOp.Replace)
        return true;
    };

    const eventHandler: DiagramEventHandler = {
        onClick, onBoxSelection
    };

    return(
            <Viewport
                key={name}
                style={{ width: "100%", height: "100%", backgroundColor: bgColor}}
                extent={extent || new Extent(0.0, -1.0, 1.0, 0.0)}
                initialViewpoint={viewpoint}
                {...eventHandler}> 
                {props.proteusModel instanceof Document && <KonvaLayerGroup>
                        <ProteusRenderer model={props.proteusModel} debugHitCanvas={false} notificationCallback={notificationCallback} colorMode={props.colorMode} />
                        <Layer scaleX={1} scaleY={-1} offsetY={0} listening={true}>
                            {connectivityMode && <ConnectivityRenderer model={proteusModel} />}
                        </Layer>
                        <SelectionRenderer selection={selection} elementCache={elementCache}/>
                        <CrossPageConnectionButton selection={props.selection} elementCache={elementCache} />
                        <BoxSelectionLayer />
                        <SelectionFitter
                            elementCache={elementCache}
                            selection={zoomToSelection && selection.length > 0 ? selection : EMPTY_ARRAY}
                        />
                    </KonvaLayerGroup>}
            </Viewport>
    );
}

export default DiagramCanvas;
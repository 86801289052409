import { FileInput, FormGroup } from '@blueprintjs/core';
import URI from '@theia/core/lib/common/uri';
import { FileDialogService } from '@theia/filesystem/lib/browser';
import React, { MouseEvent } from 'react';

export interface SubscriptionLicenseFileInputProps {

    fileURI?: string;
    fileDialogService: FileDialogService;

    setSelectedURI: (uri: string) => void;
}

export const SubscriptionLicenseFileInput = ({ fileURI, fileDialogService, setSelectedURI }: SubscriptionLicenseFileInputProps) => {

    const onClick = (event: MouseEvent<HTMLLabelElement>) => {
        event.preventDefault();

        fileDialogService.showOpenDialog({
            title: 'Import a license file',
            canSelectFiles: true,
            canSelectFolders: false,
            canSelectMany: false,
            // filters: {'Images': ['png', 'jpg']}
        }).then((uri: URI) => {
            if (uri) {
                let normalizedPath = uri.normalizePath().path.toString();
                if (normalizedPath.charCodeAt(0) === 47 /* '/' */ && normalizedPath.charCodeAt(2) === 58 /* ':' */) {
                    // ok this is windows
                    normalizedPath = normalizedPath.substring(1);
                }
                setSelectedURI(normalizedPath);
            }
        }).catch((error) => {
            console.error('unable to open file dialog ' + error)
        })
    };
    const text = fileURI ? fileURI : 'Choose file...';
    return (
        <>
            <FormGroup
                label="Select a license file"
                labelFor="license-file-input"
            >
                <FileInput id="license-file-input" fill={true} onClick={onClick} text={text} />
            </FormGroup>
        </>
    )
}
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import { CommandRegistry, DefaultResourceProvider, ILogger, MessageService } from "@theia/core";
import { LocalStorageService, ReactWidget } from "@theia/core/lib/browser";
import { FileDialogService } from '@theia/filesystem/lib/browser';
import { GettingStartedCommand } from '@theia/getting-started/lib/browser/getting-started-contribution';
import { inject, injectable, postConstruct } from 'inversify';
import React from "react";
import { LicenseStatus } from '../../common/license-service';
import { LicenseClientImpl } from '../license-client';
import { LicenseServicePreferences } from '../preferences/license-service-preference-contribution';
import { LICENSE_VIEW_WIDGET_FACTORY_ID } from './license-view-contribution';
import { LicenseView } from './react/LicenseView';

@injectable()
export class LicenseViewWidget extends ReactWidget {

    static ID = LICENSE_VIEW_WIDGET_FACTORY_ID;

    private licenseStatus: LicenseStatus;
    protected machineId: string | undefined = undefined;

    constructor(
        @inject(ILogger) protected readonly logger: ILogger,
        @inject(FileDialogService) protected readonly fileDialogService: FileDialogService,
        @inject(MessageService) protected readonly messageService: MessageService,
        @inject(DefaultResourceProvider) protected provider: DefaultResourceProvider,
        @inject(LocalStorageService) protected storageService: LocalStorageService,
        @inject(CommandRegistry) protected commandRegistry: CommandRegistry,
        @inject(LicenseClientImpl) protected readonly licenseClient: LicenseClientImpl,
        @inject(LicenseServicePreferences) protected readonly licenseServicePreferences: LicenseServicePreferences
    ) {
        super();
        this.id = LICENSE_VIEW_WIDGET_FACTORY_ID;
    }

    @postConstruct()
    protected init(): void {
        this.title.caption = 'License View';
        this.title.label = 'License View';
        this.title.closable = false;
        this.title.iconClass = 'fa fa-lock';

        this.licenseClient.getMachineId()
            .then((machineId) => {
                this.machineId = machineId;
                this.logger.info('this.machineId is ' + this.machineId);
            })
            .catch((error) => this.logger.error('unable to get machineId ' + error));

        this.licenseClient.onLicenseStatusChanged((status) => {
            this.updateLicenseStatus(status);
        });

        this.licenseClient.licenseStatus()
            .then((status) => {
                this.updateLicenseStatus(status);
            }).catch((error) => {
                console.error(error);
            });
        
        this.toDispose.pushAll([
/*             this.licenseService.onDidChangeLicenseStatus((newStatus) => {
                this.updateLicenseStatus(newStatus);
            }) */
        ]);
    }

    private updateLicenseStatus(status: LicenseStatus) {
        this.licenseStatus = status;
        this.update();
    }

    render() {
        const { active, subscriptionId, expirationDate, licenseFilePath } = this.licenseStatus;
        return (<LicenseView 
            status={active} 
            subscriptionId={subscriptionId} 
            uri={licenseFilePath ? licenseFilePath : undefined}
            expirationDate={expirationDate}
            machineId={this.machineId}
            onOnlineLicenseCheck={(subscriptionId: string) => this.onOnlineLicenseCheck(subscriptionId)}
            onOfflineLicenseCheck={(subscriptionId: string, uri: string) => this.onOfflineLicenseCheck(subscriptionId, uri)}
            fileDialogService={this.fileDialogService}
            openHelp={this.doOpenGettingStartedWidget}
        />);
    }

    protected onOnlineLicenseCheck(subscriptionId: string): Promise<void> {
        const sid = subscriptionId.trim();
        console.log('online license check with subscription id', sid);
        // Let's trim possible whitespaces here
        return this.licenseClient.checkLicense(sid)
            .then((result) => this.updateAfterLicenseCheck(result))
            .catch((error) => {
                this.logger.error(`Could not validate online license: ${error}`);
            });
    }

    protected onOfflineLicenseCheck(subscriptionId: string, uri: string): Promise<void> {
        const sid = subscriptionId.trim();
        console.log('offline license check with subscription id', sid);
        return this.licenseClient.validateLicense(sid, uri)
            .then((result) => this.updateAfterLicenseCheck(result))
            .catch((error) => {
                this.logger.error(`Could not validate offline license: ${error}`);
            });

    }

    private updateAfterLicenseCheck(status: LicenseStatus) {
        this.showLicenseCheckNofication(status);
        this.licenseStatus = status;
        this.update();
    }

    protected showLicenseCheckNofication(status: LicenseStatus) {
        console.log('license active status is ' + status.active)
        if (status.active)
            this.messageService.info('License check successful: ' + status.status);
        else
            this.messageService.error('License check failed: ' + JSON.stringify(status.status));
    }

    protected doOpenGettingStartedWidget = () => this.commandRegistry.executeCommand(GettingStartedCommand.id);
}

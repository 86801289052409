import { Colors } from "@blueprintjs/core";
import React from "react";
import { CENTER_LINE_ID_PREFIX } from "../../proteus/CenterLineIds";
import { getComponentClass, getComponentName, getId, getTagName } from "../../proteus/ProteusXML";

interface Props {
    elements: Element[];
    onChangeSelection: (elements: Element[]) => void;
}

function byFirst<T>(a: [string, T], b: [string, T]) {
    return a[0].localeCompare(b[0]);
}

function byFirstAndSecond<T>(a: [string, string, T], b: [string, string, T]) {
    const cmp = a[0].localeCompare(b[0]);
    if (cmp !== 0)
        return cmp;
    return a[1].localeCompare(b[1]);
}

function tree(props: Props, classificationName: string, rejectJustSingle: boolean, projection: (element: Element) => string | null) {
    const elementTypeMap: { [id: string]: Element[] } = {};
    for (const element of props.elements) {
        const name = projection(element) || "";
        let list = elementTypeMap[name];
        if (!list) {
            list = [];
            elementTypeMap[name] = list;
        }
        list.push(element);
    }

    const entries = Object.entries(elementTypeMap);
    if (rejectJustSingle && entries.length <= 1)
        return null;
    entries.sort(byFirst);

    const items = entries.map(([name, elements]) => {
        const tagged: [string, string, Element][] = elements.map(element => {
            const id = getId(element) || "";
            let tagName = getTagName(element);
            if (!tagName) {
                if (element.nodeName === 'CenterLine' && id.startsWith(CENTER_LINE_ID_PREFIX))
                    tagName = "CenterLine";
                else
                    tagName = id;
            }
            return [tagName, id, element];
        })
        tagged.sort(byFirstAndSecond);

        return <React.Fragment key={name}>
                <b
                    style={{color: Colors.BLUE3, cursor: 'pointer'}}
                    onClick={(event) => props.onChangeSelection(elements)}>
                    {name}
                </b>
                <p>{elements.length} elements</p>
                {tagged.map(([tagName, id, element]) =>
                    <p
                        style={{color: Colors.BLUE3, cursor: 'pointer', padding: 0, margin: 0}}
                        key={id} 
                        onClick={(event) => props.onChangeSelection([element])}>
                        {tagName}
                    </p>
                )}
                <br/>
        </React.Fragment>;
    });

    return <React.Fragment>
        <h3 style={{paddingTop: '0px', marginTop: '0px'}}>Selected elements grouped by {classificationName}</h3>
        {...items}
    </React.Fragment>;
}

export function MultiSelectionTable(props: Props): JSX.Element {
    return tree(props, "Element name", true, element => element.nodeName)
        || tree(props, "Component class", true, getComponentClass)
        || tree(props, "Component name", false, getComponentName)!;
}

import { CommandContribution, CommandRegistry, ContributionProvider, Disposable, environment, MenuAction, MenuContribution, MenuModelRegistry, MenuNode, MenuPath, SubMenuOptions } from "@theia/core";
import { CommonCommands } from "@theia/core/lib/browser";
import { EditorCommands } from "@theia/editor/lib/browser";
import { EditorWidgetFactory } from "@theia/editor/lib/browser/editor-widget-factory";
import { FileDownloadCommands } from "@theia/filesystem/lib/browser/download/file-download-command-contribution";
import { FileSystemCommands } from "@theia/filesystem/lib/browser/filesystem-frontend-contribution";
import { KeymapsCommands } from '@theia/keymaps/lib/browser';
import { FileNavigatorCommands } from "@theia/navigator/lib/browser/navigator-contribution";
import { NavigatorDiffCommands } from "@theia/navigator/lib/browser/navigator-diff";
import { WorkspaceCommands } from "@theia/workspace/lib/browser";
import { inject, injectable, named } from "inversify";


@injectable()
export class CustomMenuModelRegistry extends MenuModelRegistry implements CommandContribution {

    constructor(
        @inject(ContributionProvider) @named(MenuContribution)
        protected readonly contributions: ContributionProvider<MenuContribution>,
        @inject(CommandRegistry) protected readonly commands: CommandRegistry
    ) {
        super(contributions, commands);
        console.log('constructing new customMenuModelRegistry');
    }

    registerMenuAction(menuPath: MenuPath, item: MenuAction): Disposable {
        if (COMMON_BLOCKED_COMMANDS.includes(item.commandId)) {
            // do not add blocked menu 
            return this.disposable(menuPath);
        }
        if (environment.electron.is() && ELECTRON_BLOCKED_COMMANDS.includes(item.commandId)) {
            return this.disposable(menuPath);
        } else if (!environment.electron.is() && BROWSER_BLOCKED_COMMANDS.includes(item.commandId)) {
            return this.disposable(menuPath);
        }
        return super.registerMenuAction(menuPath, item);
    }

    registerMenuNode(menuPath: MenuPath, menuNode: MenuNode): Disposable {
        if (COMMON_BLOCKED_MENU_NODES.includes(menuPath[1])) {
            // do not add blocked menu 
            return this.disposable(menuPath);
        }
        if (environment.electron.is() && ELECTRON_BLOCKED_MENU_NODES.includes(menuPath[1])) {
            return this.disposable(menuPath);
        } else if (!environment.electron.is() && BROWSER_BLOCKED_MENU_NODES.includes(menuPath[1])) {
            return this.disposable(menuPath);
        }
        return super.registerMenuNode(menuPath, menuNode)
    }

    registerSubmenu(menuPath: MenuPath, label: string, options?: SubMenuOptions): Disposable {
        if (COMMON_BLOCKED_MENU_NODES.includes(menuPath[1])) {
            // do not add blocked menu 
            return this.disposable(menuPath);
        }
        if (environment.electron.is() && ELECTRON_BLOCKED_MENU_NODES.includes(menuPath[1])) {
            return this.disposable(menuPath);
        } else if (!environment.electron.is() && BROWSER_BLOCKED_MENU_NODES.includes(menuPath[1])) {
            return this.disposable(menuPath);
        }
        return super.registerSubmenu(menuPath, label, options);
    }

    registerCommands(commandRegistry: CommandRegistry): void {
        console.log('unregister commands')
        COMMON_BLOCKED_COMMANDS.forEach((command) => commandRegistry.unregisterCommand(command));
        if (environment.electron.is()) {
            ELECTRON_BLOCKED_COMMANDS.forEach((command) => commandRegistry.unregisterCommand(command));
        }
        else {
            BROWSER_BLOCKED_COMMANDS.forEach((command) => commandRegistry.unregisterCommand(command));
        }
    }


    private disposable(menuPath: MenuPath): Disposable {
        return {
            dispose: () => {
                console.log('no need to dispose ' + menuPath);
            }
        };
    }
}

const COMMON_BLOCKED_MENU_NODES: string[] = [
    '2_edit', 
    '3_selection', 
    '5_go', 
    '7_terminal'
];

const BROWSER_BLOCKED_MENU_NODES: string[] = [
    '1_file'
];

const ELECTRON_BLOCKED_MENU_NODES: string[] = [
];

const COMMON_BLOCKED_COMMANDS: string[] = [
    CommonCommands.AUTO_SAVE.id,
    CommonCommands.SAVE.id,
    CommonCommands.SAVE_ALL.id,
    WorkspaceCommands.NEW_FOLDER.id,
    WorkspaceCommands.NEW_FILE.id,
    WorkspaceCommands.OPEN_FILE.id,
    WorkspaceCommands.SAVE_AS.id,
    WorkspaceCommands.SAVE_WORKSPACE_AS.id,
    EditorCommands.TOGGLE_MINIMAP.id,
    EditorCommands.TOGGLE_RENDER_WHITESPACE.id,
    EditorCommands.TOGGLE_WORD_WRAP.id,
    'problemsView:toggle',
    CommonCommands.OPEN_VIEW.id,
    'workbench.action.showCommands',
    CommonCommands.TOGGLE_BOTTOM_PANEL.id,
    CommonCommands.COLLAPSE_ALL_PANELS.id,
    //CommonCommands.OPEN_PREFERENCES.id,
    CommonCommands.SELECT_COLOR_THEME.id,
    CommonCommands.SELECT_ICON_THEME.id,
    KeymapsCommands.OPEN_KEYMAPS.id,
    NavigatorDiffCommands.COMPARE_FIRST.id,
    NavigatorDiffCommands.COMPARE_SECOND.id,
    'file.openWith.' + EditorWidgetFactory.ID,
];


// Block all remaining file explorer related commands for the trial browser application 
export const BROWSER_BLOCKED_COMMANDS: string[] = [
    WorkspaceCommands.OPEN.id,
    WorkspaceCommands.OPEN_FILE.id,
    WorkspaceCommands.OPEN_FOLDER.id,
    WorkspaceCommands.OPEN_RECENT_WORKSPACE.id,
    WorkspaceCommands.ADD_FOLDER.id,
    WorkspaceCommands.OPEN_WORKSPACE.id,
    WorkspaceCommands.ADD_FOLDER.id,
    WorkspaceCommands.CLOSE.id,
    WorkspaceCommands.FILE_COMPARE.id,
    WorkspaceCommands.FILE_DELETE.id,
    WorkspaceCommands.FILE_DUPLICATE.id,
    WorkspaceCommands.FILE_RENAME.id,
    WorkspaceCommands.OPEN.id,
    WorkspaceCommands.OPEN_FOLDER.id,
    WorkspaceCommands.OPEN_RECENT_WORKSPACE.id,
    WorkspaceCommands.OPEN_WORKSPACE.id,
    WorkspaceCommands.OPEN_WORKSPACE_FILE.id,
    WorkspaceCommands.REMOVE_FOLDER.id,
    CommonCommands.COPY_PATH.id,
    CommonCommands.COPY.id,
    CommonCommands.PASTE.id,
    FileDownloadCommands.DOWNLOAD.id,
    FileDownloadCommands.COPY_DOWNLOAD_LINK.id,
    FileNavigatorCommands.COPY_RELATIVE_FILE_PATH.id,
    FileSystemCommands.UPLOAD.id
];

export const ELECTRON_BLOCKED_COMMANDS: string[] = [
    'view.reload',
    'view.resetZoom',
    'view.zoomIn',
    'view.zoomOut',
    'theia.toggleDevTools'
];
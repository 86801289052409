import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';

export interface SubscriptionExpirationInputProps {

    subscriptionIdExpirationDate?: Date;
}

export const SubscriptionExpirationInput = ({ subscriptionIdExpirationDate }: SubscriptionExpirationInputProps) => {

    const licenseValidDates = subscriptionIdExpirationDate ? (
        (((new Date(subscriptionIdExpirationDate).getTime() - new Date().getTime())) / 86400000).toFixed(0) + " days"
    ) : "";

    return (
        <>
            { subscriptionIdExpirationDate ? (
            <FormGroup
                label="Subscription expiration date:"
                labelFor="subsription-expiration-date-input"
                helperText={licenseValidDates}
            >
                <InputGroup disabled={true} id="subsription-expiration-date-input" value={subscriptionIdExpirationDate.toLocaleString()} intent={"success"}/>
            </FormGroup>
            ): null }
        </>
    )
}
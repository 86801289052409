import * as ProteusXML from '../browser/proteus/ProteusXML';

export interface DexpiElement extends HTMLElement {

}

export namespace DexpiElement {

    export function is(arg: Object | undefined): arg is DexpiElement {
        return !!arg && typeof arg === 'object'
            && ('nodeName' in arg && typeof arg['nodeName'] === 'string');
            // TODO: add more properties from DexpiElement interface here to check
    }

    export function getParentElements(element: DexpiElement, acceptedElements: string[]): DexpiElement[] {
        const parents = [];
        while( element.parentElement !== null) {
            element = element.parentElement as DexpiElement;
            if (acceptedElements.includes(element.localName))
                parents.push(element);
        }
        return parents;
    }

    export function getId(element: DexpiElement): string | null {
        return ProteusXML.getId(element);
    }
}
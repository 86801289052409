import { DefaultPropertyViewWidgetProvider } from "@theia/property-view/lib/browser/property-view-widget-provider";
import { inject, injectable } from "inversify";
import { DexpiElementSelection } from "../../common/dexpi-element-selection";
import { DexpiPropertyViewBlueprintWidget } from "./dexpi-property-view-blueprintjs-widget";
import { DexpiPropertyViewTreeWidget } from "./dexpi-property-view-tree-widget";

@injectable()
export class DexpiPropertyViewWidgetProvider extends DefaultPropertyViewWidgetProvider {

    //@inject(DexpiPropertyViewTreeWidget) protected readonly widget: DexpiPropertyViewTreeWidget;
    @inject(DexpiPropertyViewBlueprintWidget) protected readonly widget: DexpiPropertyViewTreeWidget;

    readonly id = 'dexpi-xmls';
    readonly label = 'DexpiPropertyViewWidgetProvider';

    canHandle(selection: Object | undefined): number {
        return this.isDexpiElementSelection(selection) ? 1 : 0;
    }

    protected isDexpiElementSelection(selection: Object | undefined): boolean {
        return !!selection && Array.isArray(selection) && DexpiElementSelection.is(selection[0]);
    }

    provideWidget(selection: Object | undefined): Promise<DexpiPropertyViewTreeWidget> {
        return Promise.resolve(this.widget);
    }

    updateContentWidget(selection: Object | undefined): void {
        this.getPropertyDataService(selection).then(service => this.widget.updatePropertyViewContent(service, selection));
    }
}
import { CommandRegistry } from '@theia/core';
import { AbstractViewContribution, FrontendApplication, FrontendApplicationContribution, Widget } from '@theia/core/lib/browser';
import { injectable } from 'inversify';
import { LicenseViewWidget } from './license-view-widget';


export const LICENSE_VIEW_WIDGET_FACTORY_ID = 'mb-dexpi-license-view';

export const OpenLicenseViewCommand = {
    id: 'OpenLicenseView.command',
    label: "Open license view"
};

@injectable()
export class LicenseViewContribution extends AbstractViewContribution<LicenseViewWidget> implements FrontendApplicationContribution {

    constructor() {
        super({
            widgetId: LICENSE_VIEW_WIDGET_FACTORY_ID,
            widgetName: 'License View',
            defaultWidgetOptions: {
                area: 'left',
                rank: 600,
            },
            toggleCommandId: 'licenseView:toggle'
        });
    }

    async initializeLayout(app: FrontendApplication): Promise<void> {
        await this.openView();
    }

    registerCommands(commands: CommandRegistry): void {
        super.registerCommands(commands);
        commands.registerCommand(OpenLicenseViewCommand, {
            execute: () => this.openView({ reveal: true }),
        });
    }

    protected withWidget<T>(widget: Widget | undefined = this.tryGetWidget(), cb: (widget: LicenseViewWidget) => T): T | false {
        if (widget instanceof LicenseViewWidget && widget.id === LICENSE_VIEW_WIDGET_FACTORY_ID) {
            return cb(widget);
        }
        return false;
    }

}
import { getId } from "./ProteusXML";
import { v4 as uuidv4 } from 'uuid';

export const CENTER_LINE_ID_PREFIX = 'CENTER_LINE_ID'

export function addMissingCenterLineIds(model: Document): void {
    const root = model.getRootNode() as Element;
    const centerLines = root.getElementsByTagName('CenterLine');
    for (let i = 0; i < centerLines.length; ++i) {
        const centerLine = centerLines[i];
        const elementId = getId(centerLine);
        if (elementId)
            continue;
        centerLine.setAttribute('ID', CENTER_LINE_ID_PREFIX + uuidv4())
    }
}
import { environment } from '@theia/application-package/lib/environment';
import { bindViewContribution, FrontendApplicationContribution, WidgetFactory } from '@theia/core/lib/browser';
import { ContainerModule, interfaces } from 'inversify';
import { LicenseClientImpl } from '../license-client';
import { LicenseViewContribution, LICENSE_VIEW_WIDGET_FACTORY_ID } from './license-view-contribution';
import { LicenseViewWidget } from './license-view-widget';

export default new ContainerModule(bind => {
    bindLicenseViewWidget(bind);
});

export function bindLicenseViewWidget(bind: interfaces.Bind) {

    bind(LicenseClientImpl).toSelf().inSingletonScope();

    if (environment.electron.is()) {

        bind(LicenseViewWidget).toSelf();
        bind(WidgetFactory).toDynamicValue(context => ({
            id: LICENSE_VIEW_WIDGET_FACTORY_ID,
            createWidget: () => {
                return context.container.get(LicenseViewWidget)
            }
        }));
        bindViewContribution(bind, LicenseViewContribution);
        bind(FrontendApplicationContribution).toService(LicenseViewContribution);

/*         bind(LicenseViewWidgetFactory).toFactory(ctx => 
            () => createLicenseViewWidget(ctx.container)
        );

        bind(LicenseViewService).toSelf().inSingletonScope();
        bind(WidgetFactory).toService(LicenseViewService); */

/*         bindViewContribution(bind, LicenseViewContribution);
        bind(FrontendApplicationContribution).toService(LicenseViewContribution); */
    }
    
}
/* 
function createLicenseViewWidget(parent: interfaces.Container): LicenseViewWidget {
    const container = parent.createChild();
    container.bind(LicenseViewWidget).toSelf();
    container.bind(LicenseViewOptions).toConstantValue({});

    return container.get(LicenseViewWidget);
}
 */
import { Menu, Radio, RadioGroup, Tab, Tabs } from "@blueprintjs/core";
import '@blueprintjs/core/lib/css/blueprint.css';
import { Cell, Column, CopyCellsMenuItem, IMenuContext, Table } from "@blueprintjs/table";
import '@blueprintjs/table/lib/css/table.css';
import React, { useState } from "react";
import { CENTER_LINE_ID_PREFIX } from "../../proteus/CenterLineIds";
import { getId } from "../../proteus/ProteusXML";

interface Props {
    element: Element;
}

function propertyTab(title: string, rows: string[][], selectedId: string, unitsColumn: boolean){
    const getCellData = (rowIndex: number, columnIndex: number) => {
        return rows[rowIndex][columnIndex];
    };

    const renderBodyContextMenu = (context: IMenuContext) => {
        return (
            <Menu>
                <CopyCellsMenuItem context={context} getCellData={getCellData} text="Copy" />
            </Menu>
        );
    };

    const columns = [
        <Column key={'Property'} name={'Property'} cellRenderer={(rowIndex) => <Cell>{rows[rowIndex][0]}</Cell>}/>, 
        <Column key={'Value'} name={'Value'} cellRenderer={(rowIndex) => <Cell>{rows[rowIndex][1]}</Cell>}/>
    ]
    const columnWidths = [200, 400];
    if (unitsColumn) {
        columns.push(<Column key={'Units'} name={'Units'} cellRenderer={(rowIndex) => <Cell>{rows[rowIndex][2]}</Cell>}/>);
        columnWidths.push(150);
    }

    const table = <Table 
            numRows={rows.length} 
            enableRowHeader={false} 
            columnWidths={columnWidths}
            bodyContextMenuRenderer={renderBodyContextMenu}
        >
            {...columns}
        </Table>
    return <Tab 
        id={title + selectedId} 
        key={title + selectedId} 
        title={title} 
        panel={table}
    />
}

const PROPERTY_ORDER = ['ElementName', 'ID', 'TagName', 'ComponentClass', 'ComponentClassURI', 'ComponentName'];

function sortPropertyRows(rows: string[][]) {
    rows.sort((a, b) => {
        const orderA = PROPERTY_ORDER.indexOf(a[0]);
        const orderB = PROPERTY_ORDER.indexOf(b[0]);
        if (orderA !== -1 && orderB !== -1)
            return orderA < orderB ? -1 : 1;
        else if (orderA !== -1)
            return -1;
        else if (orderB !== -1)
            return 1
        return a[0] < b[0] ? -1 : 1;
    });
}

function propertyRow(name: string, value: string | null) {
    return [name, value !== null ? value : ''];
}

function attributeRow(name: string, value: string | null, units: string | null) {
    return [name, value !== null ? value : '', units != null ? units : ''];
}

function getElementTabs(element: Element, selectedId: string): JSX.Element[] {
    const tabs: JSX.Element[] = [];
    {
        const tabTitle = 'Element';
        const rows: string[][] = [];

        rows.push(propertyRow("ElementName", element.nodeName));

        for (var i = 0; i < element.attributes.length; i++){
            const attrib = element.attributes[i];
            if (attrib.name === 'ID') {
                const id = attrib.value;
                // Some of the CenterLine ids were created in this code to allow element selection,
                // they do not really exist in the Proteus xml so skip those
                if (!(element.nodeName === 'CenterLine' && id.startsWith(CENTER_LINE_ID_PREFIX)))
                    rows.push(propertyRow('ID', id));
            }
            else {
                rows.push(propertyRow(attrib.name, attrib.value));
            }
        }
        sortPropertyRows(rows);
        tabs.push(propertyTab(tabTitle, rows, selectedId, false));
    }

    element.childNodes.forEach(genericAttributes_ => {
        if (genericAttributes_.nodeName === 'GenericAttributes') {
            const genericAttributes = genericAttributes_ as Element;
            const set = genericAttributes.getAttribute("Set");
            const tabTitle = set || 'General';
            const rows: string[][] = [];

            genericAttributes.childNodes.forEach(genericAttribute_ => {
                if (genericAttribute_.nodeName === 'GenericAttribute') {
                    const genericAttribute = genericAttribute_ as Element;
                    const name = genericAttribute.getAttribute("Name");
                    const value = genericAttribute.getAttribute("Value");
                    const units = genericAttribute.getAttribute("Units");
                    if (name)
                        rows.push(attributeRow(name, value, units));
                }
            });

            tabs.push(propertyTab(tabTitle, rows, selectedId, true));
        }
    });
    return tabs
}

function getParentElements(element: Element, acceptedElements: string[]): Element[] {
    const parents = [];
    while( element.parentElement !== null) {
        element = element.parentElement;
        if (acceptedElements.includes(element.localName))
            parents.push(element);
    }
    return parents;
}

export function PropertyTable(props: Props): JSX.Element {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const elements = [props.element];
    elements.push(...getParentElements(props.element, ['PipingNetworkSegment', 'PipingNetworkSystem']));

    const radioButtons = elements.map((element, index) => {
        return <Radio key={element.localName} label={element.localName} value={index}/>;
    })

    let selectedElement = elements[selectedIndex];
    if (selectedIndex >= elements.length) {
        setSelectedIndex(0)
        selectedElement = elements[0];
    }

    let selectedId = getId(selectedElement);
    if (selectedId === null)
        selectedId = selectedElement.localName;
    
    return (
    <div>
        {elements.length > 1 && <RadioGroup
                onChange={(event) => setSelectedIndex(parseInt(event.currentTarget.value))}
                selectedValue={selectedIndex}
                inline={true}
            >
                {...radioButtons}
            </RadioGroup>
        }
        <Tabs key={selectedId} id={'propertyTabs'} renderActiveTabPanelOnly={true} vertical={true}>
            {...getElementTabs(selectedElement, selectedId!)}
        </Tabs>
    </div>
    );

}

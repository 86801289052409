import { CommandContribution, CommandRegistry, MenuContribution, MenuModelRegistry } from "@theia/core";
import { CommonMenus, FrontendApplicationContribution, KeybindingContribution, KeybindingRegistry, StatusBar } from "@theia/core/lib/browser";
import { inject, injectable } from 'inversify';
import { XmlViewerManager } from "./xml-viewer-manager";

export const ToggleConnectivityModeCommand = {
    id: 'ToggleConnectivityMode.command',
    category: 'MBViewerCommands',
    label: "Toggle connectivity mode"
};

export const ChangeColorModeDefaultCommand = {
    id: 'ChangeColorModeDefault.command',
    category: 'MBColorCommands',
    label: "Default"
};

export const ChangeColorModeWhiteCommand = {
    id: 'ChangeColorModeWhite.command',
    category: 'MBColorCommands',
    label: "White"
};

export const ChangeColorModeBlackCommand = {
    id: 'ChangeColorModeBlack.command',
    category: 'MBColorCommands',
    label: "Black"
};

export const MB_VIEWER_VIEW_SUBMENU = [...CommonMenus.VIEW, '4_MBViewerCommands'];

export const MB_VIEWER_VIEW_SUBMENU_COLORMENU = [...MB_VIEWER_VIEW_SUBMENU, '1_MBColorCommands'];

@injectable()
export class XmlViewerContribution implements FrontendApplicationContribution, CommandContribution, MenuContribution, KeybindingContribution {

    @inject(StatusBar) protected readonly statusBar: StatusBar;
    @inject(XmlViewerManager) protected readonly viewerManager: XmlViewerManager;

    private connectivityMode: boolean = false;

    private colorMode: number = 0;

    onStart(): void {
        this.updateStatusBar();
        this.viewerManager.onCurrentViewerChanged(() => this.updateStatusBar());
    }

    protected updateStatusBar(): void {
        // TODO: status bar can be updated here
    }

    registerCommands(commands: CommandRegistry): void {
        commands.registerCommand(ToggleConnectivityModeCommand, {
            execute: () => {
                this.connectivityMode = !this.connectivityMode;
                this.viewerManager.setConnectivityMode(this.connectivityMode);
            }
        });
        commands.registerCommand(ChangeColorModeDefaultCommand, {
            execute: () => {
                this.colorMode = 0;
                this.viewerManager.setColorMode(this.colorMode);
            }
        });
        commands.registerCommand(ChangeColorModeWhiteCommand, {
            execute: () => {
                this.colorMode = 1
                this.viewerManager.setColorMode(this.colorMode);
            }
        });
        commands.registerCommand(ChangeColorModeBlackCommand, {
            execute: () => {
                this.colorMode = 2
                this.viewerManager.setColorMode(this.colorMode);
            }
        });
    }

    registerMenus(menus: MenuModelRegistry): void {
        menus.registerMenuAction(MB_VIEWER_VIEW_SUBMENU, {
            commandId: ToggleConnectivityModeCommand.id,
            label: ToggleConnectivityModeCommand.label
        });

        menus.registerSubmenu(MB_VIEWER_VIEW_SUBMENU_COLORMENU, 'Change color mode');

        menus.registerMenuAction(MB_VIEWER_VIEW_SUBMENU_COLORMENU, {
            commandId: ChangeColorModeDefaultCommand.id,
            label: ChangeColorModeDefaultCommand.label
        });
        menus.registerMenuAction(MB_VIEWER_VIEW_SUBMENU_COLORMENU, {
            commandId: ChangeColorModeWhiteCommand.id,
            label: ChangeColorModeWhiteCommand.label
        });
        menus.registerMenuAction(MB_VIEWER_VIEW_SUBMENU_COLORMENU, {
            commandId: ChangeColorModeBlackCommand.id,
            label: ChangeColorModeBlackCommand.label
        });
    }

    registerKeybindings(keybindings: KeybindingRegistry): void {
        keybindings.registerKeybindings({
            command: ToggleConnectivityModeCommand.id,
            keybinding: 'ctrl+M'
        });
    }
}
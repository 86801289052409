import { TreeNode } from '@theia/core/lib/browser';
import { DirNode } from '@theia/filesystem/lib/browser';
import { FileNavigatorWidget } from "@theia/navigator/lib/browser/navigator-widget";
import { injectable } from 'inversify';

/**
 * Removes options for copy/paste and dnd file upload
 */

@injectable()
export class RestrictedFileNavigatorWidget extends FileNavigatorWidget {

    protected enableDndOnMainPanel(): void {}

    protected handleCopy(event: ClipboardEvent): void {}

    protected handlePaste(event: ClipboardEvent): void {}

    protected handleDragStartEvent(node: TreeNode, event: React.DragEvent): void {}

    protected handleDragEnterEvent(node: TreeNode | undefined, event: React.DragEvent): void {}

    protected handleDragOverEvent(node: TreeNode | undefined, event: React.DragEvent): void {}

    protected handleDragLeaveEvent(node: TreeNode | undefined, event: React.DragEvent): void {}

    protected async handleDropEvent(node: TreeNode | undefined, event: React.DragEvent): Promise<void> {}

    protected getDropTargetDirNode(node: TreeNode | undefined): DirNode | undefined {
        return undefined;
    }

}

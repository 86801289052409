import React from 'react';
import { SubscriptionExpirationInput } from './SubscriptionExpirationInput';
import { SubscriptionIdInput } from './SubscriptionIdInput';

export interface OnlinePanelProps {
    subscriptionId?: string;
    subscriptionIdExpirationDate?: Date;
    onlineLicenseCheck: (subscriptionId: string) => Promise<void>;
}

export const OnlinePanel = ({ subscriptionId, subscriptionIdExpirationDate, onlineLicenseCheck }: OnlinePanelProps) => {

    return (
        <>
            <SubscriptionIdInput subscriptionId={subscriptionId} handleSubmit={onlineLicenseCheck} />
            <SubscriptionExpirationInput subscriptionIdExpirationDate={subscriptionIdExpirationDate} />
        </>
    )
}


import { Disposable, DisposableCollection, SelectionService } from "@theia/core";
import { FrontendApplication, FrontendApplicationContribution, TreeNode } from "@theia/core/lib/browser";
import URI from "@theia/core/lib/common/uri";
import { OutlineViewService } from '@theia/outline-view/lib/browser/outline-view-service';
import { OutlineSymbolInformationNode } from '@theia/outline-view/lib/browser/outline-view-widget';
import { inject, injectable } from "inversify";
import "../../../src/browser/xml-viewer/outline-icons/outline-icons-style.css";
import { DexpiElementSelection } from "../../common/dexpi-element-selection";
import { getComponentName, getId, getIds, getTagName } from "../proteus/ProteusXML";
import { XmlViewerManager, XmlViewerOpenerOptions } from "./xml-viewer-manager";

import debounce = require('@theia/core/shared/lodash.debounce');

const proteusElementsWithIcons = [
    "CenterLine",
    "Equipment",
    "InstrumentComponent",
    "Label",
    "Nozzle",
    "PipeConnectorSymbol",
    "PipeOffPageConnector",
    "PipingComponent",
    "PipingNetworkSegment",
    "PipingNetworkSystem",
    "PlantModel",
    "ProcessInstrument",
    "PropertyBreak",
    "SignalConnectorSymbol",
    "SignalOffPageConnector",
    "SignalLine",
]

const nodeNameToIconClass = (() => {
    const result: {[key: string]: string} = {};
    for(var nodeName of proteusElementsWithIcons)
        result[nodeName] = "proteus-element-" + nodeName;
    return result;
})();


@injectable()
export class XmlViewerOutlineContribution implements FrontendApplicationContribution {

    protected readonly toDisposeOnClose = new DisposableCollection();
    protected readonly toDisposeOnViewer = new DisposableCollection();
    protected roots: XmlViewerOutlineSymbolInformationNode[] | undefined;
    protected canUpdateOutline: boolean = true;

    @inject(OutlineViewService) protected readonly outlineViewService: OutlineViewService;

    @inject(XmlViewerManager) protected readonly viewerManager: XmlViewerManager;

    @inject(SelectionService) protected selectionService: SelectionService;

    onStart(app: FrontendApplication): void {
        this.outlineViewService.onDidChangeOpenState(async open => {
            if (open) {
                this.toDisposeOnClose.push(this.toDisposeOnViewer);
                this.toDisposeOnClose.push(this.viewerManager.onCurrentViewerChanged(
                    debounce(() => this.updateOutline())
                ));
                this.toDisposeOnClose.push(this.viewerManager.onCurrentViewerChanged(
                    debounce(() => this.handleCurrentViewerChanged(), 50)
                ));
                this.handleCurrentViewerChanged();
            } else {
                this.toDisposeOnClose.dispose();
            }
        });
        this.outlineViewService.onDidSelect(async node => {
            if (XmlViewerOutlineSymbolInformationNode.is(node) && node.parent && getId(node.self)) {
                const options: XmlViewerOpenerOptions = {
                    mode: 'reveal',
                    selection: [getId(node.self)!]
                };
                await this.selectInViewer(node, node.self as HTMLElement, options);
            }
        });
        this.outlineViewService.onDidOpen(async node => {
            if (XmlViewerOutlineSymbolInformationNode.is(node) && getId(node.self)) {
                const options: XmlViewerOpenerOptions = {
                    mode: 'reveal',
                    selection: [getId(node.self)!]
                };
                await this.selectInViewer(node, node.self as HTMLElement, options);
            }
        });
    }

    protected async selectInViewer(node: XmlViewerOutlineSymbolInformationNode, elementSelection: HTMLElement, options?: XmlViewerOpenerOptions): Promise<void> {
        // Avoid cyclic updates: Outline -> Editor -> Outline.
        this.canUpdateOutline = false;
        try {
            this.selectionService.selection = DexpiElementSelection.create([elementSelection], 'xml-viewer-outline-contribution');
            // Object.assign([elementSelection], { source: 'xml-viewer-outline-contribution'});
            if(this.roots) {
                this.applySelection(this.roots, new Set([node.id]));
            }
            //await this.viewerManager.open(node.uri, options);
        } finally {
            this.canUpdateOutline = true;
        }
    }

    protected handleCurrentViewerChanged(): void {
        this.toDisposeOnViewer.dispose();
        if (this.toDisposeOnClose.disposed) {
            return;
        }
        this.toDisposeOnClose.push(this.toDisposeOnViewer);
        this.toDisposeOnViewer.push(Disposable.create(() => this.roots = undefined));
        const viewer = this.viewerManager.currentViewer;
        if (viewer) {
            this.toDisposeOnViewer.push(viewer.onModelChanged(() => {
                this.roots = undefined; // Invalidate the previously resolved roots.
                this.updateOutline();
            }));
            this.toDisposeOnViewer.push(viewer.onSelectionChanged(elements => {
                this.updateOutline(elements);
            }));
        }
        this.updateOutline();
    }

    protected async updateOutline(viewerSelection?: Element[]): Promise<void> {
        if (!this.canUpdateOutline) {
            return;
        }
        
        const viewer = this.viewerManager.currentViewer;
        const model = viewer && viewer.getProteusModel();
        const uri = viewer && viewer.getResourceUri();
        const roots = model && uri && await this.createRoots(uri, model, viewerSelection);
        this.outlineViewService.publish(roots || []);
    }

    protected async createRoots(uri: URI, model: Document, viewerSelection?: Element[]): Promise<XmlViewerOutlineSymbolInformationNode[]> {
        if (this.roots && this.roots.length > 0) {
            // Reset the selection on the tree nodes, so that we can apply the new ones based on the `editorSelection`.
            const resetSelection = (node: XmlViewerOutlineSymbolInformationNode) => {
                node.selected = false;
                node.children.forEach(resetSelection);
            };
            this.roots.forEach(resetSelection);
        } else {
            this.roots = [];

            const nodes = this.createNodes(uri, model);
            this.roots.push(...nodes);
        }
        
        if (viewerSelection) {
            const viewerSelectionIds = getIds(viewerSelection);
            this.applySelection(this.roots, new Set(viewerSelectionIds));
        }
        return this.roots;
    }

    protected createNodes(uri: URI, model: Document): XmlViewerOutlineSymbolInformationNode[] {
        const roots: XmlViewerOutlineSymbolInformationNode[] = [];

        const ids = new Map();
        for (let index = 0; index < model.children.length; index++) {
            const node = model.children.item(index);
            if (node) {
                const info = this.createNode(uri, node, ids);
                roots.push(info);
            }
        }
        return roots;
    }

    protected createNode(uri: URI, node: Element, ids: Map<string, number>, parent?: XmlViewerOutlineSymbolInformationNode): XmlViewerOutlineSymbolInformationNode {
        const id = getId(node)
        const children: XmlViewerOutlineSymbolInformationNode[] = [];
        const infoNode: XmlViewerOutlineSymbolInformationNode = {
            self: node,
            children,
            id: id!,
            iconClass: this.getIconClass(node),
            name: this.getName(node),
            detail: this.getDetail(node),
            parent,
            uri,
            selected: false,
            expanded: this.shouldExpand(node)
        };

        const childNodes: Element[] = []
        if (node.childNodes.length > 0) {
            for (let index = 0; index < node.children.length; index++) {
                const childNode = node.children.item(index);
                if (childNode && getId(childNode) !== null) {
                    childNodes.push(childNode);
                }
            }
        }
        if (node.nodeName !== 'PipingNetworkSegment')
            childNodes.sort((a, b) => this.getName(a).localeCompare(this.getName(b)));
        childNodes.forEach((childNode) => XmlViewerOutlineSymbolInformationNode.insert(children, this.createNode(uri, childNode, ids, infoNode)));

        return infoNode;
    }

    protected getName(node: Element): string {
        let label = getTagName(node);
        if(label === null || label.length === 0)
            label = node.nodeName;

        const componentName = getComponentName(node);
        if (componentName)
            label += ' (' + componentName + ')';
        
        return label;
    }

    protected getDetail(node: Node): string {
        return 'detail';
    }

    protected getIconClass(node: Node): string {
        return nodeNameToIconClass[node.nodeName];
    }

    protected createId(name: string, ids: Map<string, number>): string {
        const counter = ids.get(name);
        const index = typeof counter === 'number' ? counter + 1 : 0;
        ids.set(name, index);
        return name + '_' + index;
    }

    protected shouldExpand(node: Node): boolean {
        return false;
        /* return [
            SymbolKind.Class,
            SymbolKind.Enum, SymbolKind.File,
            SymbolKind.Interface, SymbolKind.Module,
            SymbolKind.Namespace, SymbolKind.Object,
            SymbolKind.Package, SymbolKind.Struct
        ].indexOf(symbol.kind) !== -1; */
    }

    /**
     * Sets the selection on the sub-trees based on the optional editor selection.
     * Select the narrowest node that is strictly contains the editor selection.
     */
    protected applySelection(roots: XmlViewerOutlineSymbolInformationNode[], viewerSelection: Set<string>): boolean {
        for (const root of roots) {
            if (viewerSelection.has(root.id)) {
                root.selected = true;
                let parent = root.parent;
                while (parent) {
                    parent.expanded = true;
                    parent = parent.parent;
                }
                return true;
            } else {
                if(root.selected)
                    root.selected = false;
                if (this.applySelection(root.children, viewerSelection)) {
                    return true;
                }
            }
        }
        return false;
    }
}


export interface XmlViewerOutlineSymbolInformationNode extends OutlineSymbolInformationNode {

    uri: URI;
    self: Element;

    id: string;
    parent: XmlViewerOutlineSymbolInformationNode | undefined;
    children: XmlViewerOutlineSymbolInformationNode[];

    detail: string | undefined;
}

export namespace XmlViewerOutlineSymbolInformationNode {
    export function is(node: TreeNode): node is XmlViewerOutlineSymbolInformationNode {
        return OutlineSymbolInformationNode.is(node) && 'uri' in node && 'id' in node;
    }
    export function insert(nodes: XmlViewerOutlineSymbolInformationNode[], node: XmlViewerOutlineSymbolInformationNode): void {
        nodes.push(node);
/*         const index = nodes.findIndex(current => compare(node, current) < 0);
        if (index === -1) {
            nodes.push(node);
        } else {
            nodes.splice(index, 0, node);
        } */
    }
    export function compare(node: XmlViewerOutlineSymbolInformationNode, node2: XmlViewerOutlineSymbolInformationNode): number {
        return -1;
    }
}


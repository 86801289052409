import { ViewportContext } from "@modelbroker/diagram-viewport";
import { KonvaEventObject } from "konva/types/Node";
import React, { useMemo, useRef, useState } from "react";
import { Layer, Rect } from 'react-konva';
import { Extent } from "../proteus/Extent";
import { Position } from "../proteus/Position";


export interface SelectionRectangle {
    begin: Position;
    end: Position;
}

export function extentFromSelectionRectangle(box: SelectionRectangle): Extent {
    const { begin, end } = box;
    return new Extent(
        Math.min(begin.x, end.x),
        Math.min(begin.y, end.y),
        Math.max(begin.x, end.x),
        Math.max(begin.y, end.y)
    );
}

type SelectionFinishedFunc = (selection: SelectionRectangle, event: KonvaEventObject<MouseEvent>) => void;
type ClickedFunc = (position: Position, event: KonvaEventObject<MouseEvent>) => void;

interface Callbacks {
    onMouseDown: (position: Position, scale: number, event: KonvaEventObject<MouseEvent>) => void;
    onMouseMove: (position: Position, scale: number, event: KonvaEventObject<MouseEvent>) => void;
    onMouseUp: (position: Position, scale: number, event: KonvaEventObject<MouseEvent>) => void;
}

export function useSelectionRectangle(props: { onSelectionFinished?: SelectionFinishedFunc, onClick?: ClickedFunc }) {
    const { onSelectionFinished, onClick } = props;

    const beginRef = useRef(null as Position | null);
    const [selectionRectangle, setSelectionRectangle] = useState(null as SelectionRectangle | null);

    const callbacks: Callbacks = useMemo(() => ({
        onMouseDown: (position: Position, scale: number, event: KonvaEventObject<MouseEvent>) => {
            beginRef.current = position;
        },
        onMouseMove: (position: Position, scale: number, event: KonvaEventObject<MouseEvent>) => {
            const begin = beginRef.current;
            if (begin) {
                setSelectionRectangle({ begin, end: position });
            }
        },
        onMouseUp: (position: Position, scale: number, event: KonvaEventObject<MouseEvent>) => {
            const begin = beginRef.current;

            // Clear selection box
            beginRef.current = null;
            setSelectionRectangle(null);

            // Is this a box selection?
            if (begin) {
                const maxClickChange = 3 / scale;
                if (Math.abs(begin.x - position.x) > maxClickChange || Math.abs(begin.y - position.y) > maxClickChange) {
                    if (onSelectionFinished) {
                        onSelectionFinished({ begin, end: position }, event);
                    }
                    return;
                }
            }

            // Otherwise it is a click
            if (onClick)
                onClick(position, event);
        },
    }), [onSelectionFinished, onClick]);

    return {
        selectionRectangle,
        ...callbacks
    };
}

export function SelectionRectangleRenderer(props: {
    selectionRectangle: SelectionRectangle | null
}) {
    const { selectionRectangle } = props;
    if (!selectionRectangle)
        return null;
    const { begin, end } = selectionRectangle;
    const rect = <ViewportContext.Consumer>{({scale}) => {
        const strokeProps = {
            stroke: 'black',
            strokeWidth: 1.0 / scale,
            dash: [5 / scale, 5 / scale]
        };
        return <Rect
            x={Math.min(begin.x, end.x)}
            y={Math.min(begin.y, end.y)}
            width={Math.abs(begin.x - end.x)}
            height={Math.abs(begin.y - end.y)}
            {...strokeProps}
        />;
    }}</ViewportContext.Consumer>;
    return <Layer listening={false}>
        {rect}
    </Layer>;
}

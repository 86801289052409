import { ViewportContext } from "@modelbroker/diagram-viewport";
import React from "react";
import { Layer, Line, Rect } from "react-konva";
import { getId, isConnectionType, readCoordinates } from '../proteus/ProteusXML';
import { ElementCache } from "./ElementCache";
import { polylineToSelectionShape } from "./PolylineSelectionShape";

interface Props {
    selection: string[];
    elementCache: ElementCache;
}

export function getConnectionSelectionGraphics(strokeProperties: any, padding: number, element: Element): JSX.Element[] | null {
    if (element.localName === 'SignalLine' || element.localName === 'InformationFlow') {
        const children: JSX.Element[] = [];
        element.childNodes.forEach((child) => {
            if (child instanceof Element && child.localName === 'CenterLine') {
                const elements = getConnectionSelectionGraphics(strokeProperties, padding, child);
                if (elements)
                    for (const element of elements)
                        children.push(element);
            }
        });
        return children;
    }
    else if (element.localName === 'CenterLine') {
        const coordinates = readCoordinates(element);
        return [<Line
            key={getId(element)}
            points={polylineToSelectionShape(coordinates, padding)}
            closed
            {...strokeProperties} />];
    }
    else
        return null;
}


export function SelectionRenderer(props: Props): JSX.Element {
    props.elementCache.getElements();
    return <Layer listening={false} scaleY={-1}>
        <ViewportContext.Consumer>{({scale}) => {
            const selectionWidth = 3 / scale;
            const padding = 7 / scale;
            const strokeProps = {
                stroke: '#007FFF',
                strokeWidth: selectionWidth,
                //dash: [6 / scale, 4 / scale]
            }
            return props.selection.flatMap((id: string) => {
                const element = props.elementCache.getElement(id);
                if (typeof element === 'undefined')
                    return [];
                if (isConnectionType(element.localName))
                    return getConnectionSelectionGraphics(strokeProps, padding, element);
                else {
                    const extent = props.elementCache.getExactExtent(element)
                    if (extent === undefined)
                        return [];
                    const id = getId(element);
                    if (id === null)
                        return [];

                    const { minX, minY, maxX, maxY } = extent;
                    return [<Rect
                        key={'selection-' + id}
                        x={minX - padding}
                        y={minY - padding}
                        width={maxX - minX + 2 * padding}
                        height={maxY - minY + 2 * padding}
                        {...strokeProps}
                    />];
                }
            })
        }}</ViewportContext.Consumer>
    </Layer>;
}

import { useRef } from '@theia/core/shared/react';
import * as React from 'react';
import { FallbackProps, withErrorBoundary } from "react-error-boundary";
import { addMissingCenterLineIds } from '../../proteus/CenterLineIds';
import { getElementCache } from '../../proteus/ElementCache';
import { getDescendantsWithId, getId } from '../../proteus/ProteusXML';
import DiagramCanvas, { SelectionOp } from "./DiagramCanvas";

interface ReactXmlViewerProps {
    name: string;
    proteusModel: Document;
    selection: string[];
    connectivityMode: boolean;
    colorMode: number;
    onElementSelection: (elements: HTMLElement[]) => void;
    onFindConnectedOPC: (opc: Element) => void;
    notificationCallback: (message: string) => void;
}

function selectionsDiffer(xs: string[], ys: string[]): boolean {
    if(xs.length !== ys.length)
        return true;
    else 
        return xs.some(x => !ys.includes(x));
}

const ReactXmlViewer = (props: ReactXmlViewerProps) => {
    const { proteusModel, onElementSelection, name, connectivityMode, colorMode, notificationCallback } = props;
    //const [selection, setSelection] = useState<string[]>([]);

    const elementCache = getElementCache(notificationCallback, proteusModel);
    const previousInternalSelection = useRef([] as string[]);

    const selectionIds = [];
    for (const elementId of props.selection) {
        const element = elementCache.getElement(elementId);
        if (!element)
            continue;
        if (element.nodeName === 'PipingNetworkSegment' || element.nodeName === 'PipingNetworkSystem') {
            const descendantIds = getDescendantsWithId(element).map((el) => getId(el)!);
            for (const descendantId of descendantIds) {
                const descendant = elementCache.getElement(descendantId);
                if (descendant && !(descendant.nodeName === 'PipingNetworkSegment' || descendant.nodeName === 'PipingNetworkSystem'))
                    selectionIds.push(descendantId);
            }
        }
        else {
            selectionIds.push(elementId);
        }
    }

    React.useEffect(() => {
        addMissingCenterLineIds(proteusModel);
    }, [props.proteusModel])

    const handleSelection = (ids: string[], op: SelectionOp) => {
        const selectionIds = op === SelectionOp.Add ? [...new Set([...props.selection, ...ids])] : [...ids];
        const selectedElements = elementCache.getElementsByIds(selectionIds);
        previousInternalSelection.current = selectionIds;
        //setSelection(selectionIds);
        onElementSelection(selectedElements);
    };

    return (
        <DiagramCanvas
            proteusModel={proteusModel}
            selection={selectionIds}
            handleSelection={handleSelection}
            onFindConnectedOPC={props.onFindConnectedOPC}
            name={name}
            connectivityMode={connectivityMode}
            colorMode={colorMode}
            notificationCallback={notificationCallback}
            zoomToSelection={selectionsDiffer(selectionIds, previousInternalSelection.current)}
        />
    );
}

function FallbackComponent(props: FallbackProps) {
    const { error, resetErrorBoundary } = props;
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

export default withErrorBoundary(ReactXmlViewer, {FallbackComponent: FallbackComponent});

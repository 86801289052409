import { Event } from "@theia/core";
import { DidChangeLabelEvent, LabelProviderContribution } from "@theia/core/lib/browser";
import URI from "@theia/core/lib/common/uri";
import { FileStat } from "@theia/filesystem/lib/common";
import { injectable } from 'inversify';

@injectable()
export class XmlViewerLabelProviderContribution implements LabelProviderContribution {
    canHandle(uri: object): number {
        let toCheck = uri;
        if (FileStat.is(toCheck)) {
            toCheck = new URI(toCheck.uri);
        }
        if (toCheck instanceof URI) {
            if (toCheck.path.ext.toLowerCase() === '.xml') {
                return 1000;
            }
        }
        return 0;
    }
    onDidChange?: Event<DidChangeLabelEvent> | undefined;

}
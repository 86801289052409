import { WebSocketConnectionProvider } from '@theia/core/lib/browser';
import { PreferenceConfigurations } from '@theia/core/lib/browser/preferences/preference-configurations';
import { FileNavigatorFilter } from "@theia/navigator/lib/browser/navigator-filter";
import { WorkspaceService } from '@theia/workspace/lib/browser';
import { ContainerModule } from 'inversify';
import '../../src/browser/style/editor.css';
import { licenseServicePath, LicenseServiceServer } from '../common/license-service';
import { bindMBAboutDialogContribution } from './about/mb-about-dialog-frontend-module';
import { bindDexpiPropertyView } from './dexpi-property-view/dexpi-property-view-container';
import { bindMBGettingStartedContribution } from './getting-started/mb-getting-started-frontend-module';
import { bindLicenseViewWidget } from './license/license-view-frontend-module';
import { XMLFileNavigatorFilter } from './navigator/xml-file-navigator-filter';
import { bindOpcNavigationFrontendService } from './opc/opc-navigation-frontend-module';
import { bindLicenseServicePreferenceProvider } from './preferences/license-service-preference-contribution';
import { MBPreferenceConfigurations } from './preferences/mb-dexpi-viewer-preference-configurations';
import { bindRemovalContribution } from './removal/removal-frontend-module';
import { ViewerWorkspaceService } from './viewer-workspace-service';
import { bindXmlViewerWidget } from './xml-viewer/xml-viewer-frontend-module';

export default new ContainerModule((bind, unbind, isBound, rebind) => {
    console.log('on frontend start');

    bind(ViewerWorkspaceService).toSelf().inSingletonScope();
    rebind(WorkspaceService).to(ViewerWorkspaceService).inSingletonScope();

    bindLicenseServicePreferenceProvider(bind);

    bind(LicenseServiceServer).toDynamicValue(ctx => {
        return WebSocketConnectionProvider.createProxy(ctx.container, licenseServicePath);
    }).inSingletonScope();

    bindXmlViewerWidget(bind);
    bindMBAboutDialogContribution(bind, unbind, isBound, rebind);
    bindDexpiPropertyView(bind);
    bindLicenseViewWidget(bind);
    bindMBGettingStartedContribution(bind);
    bindRemovalContribution(bind, isBound, rebind);
    bindOpcNavigationFrontendService(bind);
    rebind(FileNavigatorFilter).to(XMLFileNavigatorFilter).inSingletonScope()
    rebind(PreferenceConfigurations).to(MBPreferenceConfigurations).inSingletonScope();

});


export function polylineToSelectionShape(polyline: number[], padding: number): number[] {
    const tolerance = 1e-3 * padding;

    // Filter coordinates and find directions
    const filteredCoordinates = [];
    let oldX = polyline[0];
    let oldY = polyline[1];
    filteredCoordinates.push(oldX);
    filteredCoordinates.push(oldY);
    const dirs = [];
    let oldDx = 0, oldDy = 0;
    for (var i = 2; i < polyline.length; i += 2) {
        const x = polyline[i];
        const y = polyline[i + 1];
        let dx = x - oldX;
        let dy = y - oldY;
        const len = Math.hypot(dx, dy);
        if (len < tolerance)
            continue;
        dx /= len;
        dy /= len;
        
        if(filteredCoordinates.length === 2) {
            dirs.push(dy);
            dirs.push(-dx);
        }
        else {
            const cp = dx * oldDy - dy * oldDx;
            if(Math.abs(cp) < 1e-3) {
                filteredCoordinates.splice(filteredCoordinates.length-2, 2);
            }
            else {
                dirs.push(cp*(dx-oldDx));
                dirs.push(cp*(dy-oldDy));
            }
        }

        filteredCoordinates.push(x);
        filteredCoordinates.push(y);
        oldX = x;
        oldY = y;
        oldDx = dx;
        oldDy = dy;
    }
    dirs.push(oldDy);
    dirs.push(-oldDx);

    // Create shape
    const result = [];
    for(var i=0;i<filteredCoordinates.length;i+=2) {
        const x = filteredCoordinates[i];
        const y = filteredCoordinates[i + 1];
        const dx = dirs[i];
        const dy = dirs[i + 1];
        result.push(x + dx*padding);
        result.push(y + dy*padding);
    }
    for(var i=filteredCoordinates.length-2;i>=0;i-=2) {
        const x = filteredCoordinates[i];
        const y = filteredCoordinates[i + 1];
        const dx = dirs[i];
        const dy = dirs[i + 1];
        result.push(x - dx*padding);
        result.push(y - dy*padding);
    }
    return result;
}
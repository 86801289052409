import React from "react";
import styled from 'styled-components';
import acknowledgments from "./acknowledgments.json";

interface LicenseInfo {
    licenses: string;
    repository?: string;
    licenseUrl?: string;
}

const typedAcknowledgments: {[key: string]: LicenseInfo} = acknowledgments;

const AcknowledgmentsFrame = styled.div`
width: min(80em,80vw);
height: min(60em,75vh);
overflow: auto;
`;

const skippedPackages = new Set([
    "@"
])

function acknowledgmentFor(openLink: (link: string) => Promise<void>, package_name: string, info: LicenseInfo) {
    if(skippedPackages.has(package_name))
        return null;
    var package_: JSX.Element | string = package_name;
    if(info.repository)
        package_ = <a onClick={() => openLink(info.repository!)}>{package_}</a>;
    var license: JSX.Element | string = info.licenses;
    if(info.licenseUrl)
        license = <a onClick={() => openLink(info.licenseUrl!)}>{license}</a>;
    return <li key={package_name}>{package_}, license: {license}</li>;
}

export default (props: { openLink: (link: string) => Promise<void> }) => (
    <AcknowledgmentsFrame>
        <p>MB DEXPI Viewer uses code from the following libraries:</p>
        <ul>
            {Object.keys(typedAcknowledgments).map(package_name => acknowledgmentFor(props.openLink, package_name, typedAcknowledgments[package_name]))}
        </ul>
    </AcknowledgmentsFrame>
);
import { ViewportContext } from "@modelbroker/diagram-viewport";
import React from "react";
import { Layer, Rect, Text } from "react-konva";
import { getId, isCrossPageConnectionType } from "../proteus/ProteusXML";
import { ElementCache } from "./ElementCache";

interface Props {
    selection: string[];
    elementCache: ElementCache;
}

export function CrossPageConnectionButton(props: Props) {
    props.elementCache.getElements();
    return <Layer listening={true} scaleY={-1}>
        <ViewportContext.Consumer>{({scale}) => {
            const selectionWidth = 3 / scale;
            const padding = 7 / scale;
            const tscale = 1.0 / scale;

            const graphics: JSX.Element[] = [];

            if (props.selection.length !== 1)
                return [];
            const element = props.elementCache.getElement(props.selection[0]);
            if (!element || !isCrossPageConnectionType(element.localName))
                return [];

            const extent = props.elementCache.getExactExtent(element);
            if (extent === undefined)
                return [];
            const id = getId(element);
            if (id === null)
                return [];

            const { minX, maxY } = extent;
            const buttonWidth = tscale * 170;
            const buttonHeight = tscale * 30;
            const x = minX - padding;
            const y = maxY + padding + selectionWidth;

            const createButton = (prefix: string, label: string, x: number, y: number, width: number, height: number) => {
                graphics.push(<Rect
                    key={prefix + "_rect"}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill='#007FFF'
                    cornerRadius={selectionWidth * 2}
                    strokeWidth={selectionWidth}
                    customButton={label}
                />);
                graphics.push(<Text
                    key={prefix + "_text"}
                    x={x + padding}
                    y={y + height * 0.7}
                    align='left'
                    verticalAlign='center'
                    text={label}
                    scaleY={- tscale}
                    scaleX={tscale}
                    fontSize={14}
                    fill='white'
                    fontFamily='Helvetica'
                    fontStyle='bold'
                    listening={false}
                    hitStrokeWidth={0}
                />);
            };

            createButton("j", 'Jump to Connected', x, y, buttonWidth, buttonHeight);
            return graphics;
        }}</ViewportContext.Consumer>
    </Layer>;
}

import { createPreferenceProxy, PreferenceContribution, PreferenceProxy, PreferenceSchema, PreferenceService } from "@theia/core/lib/browser";
import { interfaces } from "inversify";
import { LicenseServiceServer } from "../../common/license-service";

export const licenseServicePreferenceSchema: PreferenceSchema = {
    "type": "object",
    "properties" : {
        "licenseService.proxyAddress": {
            "description": "Proxy server address",
            "type": "string"
        }
    }
}

export interface LicenseServiceConfiguration {
    'licenseService.proxyAddress': string;
}

export const LicenseServicePreferences = Symbol('CorePreferences');

export type LicenseServicePreferences = PreferenceProxy<LicenseServiceConfiguration>;

export function createLicenseServicePreferences(preferences: PreferenceService): LicenseServicePreferences {
    return createPreferenceProxy(preferences, licenseServicePreferenceSchema);
}
export const bindLicenseServicePreferenceProvider = (bind: interfaces.Bind) => {
    bind(LicenseServicePreferences).toDynamicValue((ctx) => {
        const preferences = ctx.container.get(PreferenceService) as PreferenceService;
        preferences.onPreferenceChanged((e) => {
            if (e.preferenceName === 'licenseService.proxyAddress') {
                console.log('licenseService.proxyAddress = ' + e.newValue);
                const licenseServer = ctx.container.get(LicenseServiceServer) as LicenseServiceServer;
                licenseServer.updateLicenseClientOptions({
                    proxyAddress: e.newValue,
                });
            }
        });
        return createLicenseServicePreferences(preferences);
    }).inSingletonScope();
    bind(PreferenceContribution).toConstantValue({
        schema: licenseServicePreferenceSchema
    });
}
import { WorkspaceService } from "@theia/workspace/lib/browser";
import { injectable } from "inversify";

@injectable()
export class ViewerWorkspaceService extends WorkspaceService {

    /**
     * 
     * this is overriden because we do not want to allow switching workspace via URL fragment in trial service
     */
    protected async doGetDefaultWorkspaceUri(): Promise<string | undefined> {
        return this.server.getMostRecentlyUsedWorkspace();
    }

    protected setURLFragment(workspacePath: string): void {
        // do nothing
    }

    get isMultiRootWorkspaceEnabled(): boolean {
        return false;
    }
}
import { AboutDialog, ABOUT_CONTENT_CLASS } from "@theia/core/lib/browser/about-dialog";
import { injectable } from 'inversify';
import React from "react";
import LicenseText from "./license-text";
import Acknowledgments from "./acknowledgments";
import { Tab, Tabs } from "@blueprintjs/core";
import logo from '../../../src/browser/about/MB2.png';
import { OpenerService } from '@theia/core/lib/browser';
import { inject } from "@theia/core/shared/inversify";
import URI from '@theia/core/lib/common/uri';
import styled from 'styled-components';

const PRODUCT_WEBPAGE = 'https://www.semantum.fi/products/modelbroker/modelbrokerdexpiviewer/';

const TdProp = styled.td`
    font-weight: bold;
`;

const TdValue = styled.td`
`;

@injectable()
export class MBAboutDialog extends AboutDialog {
    @inject(OpenerService)
    private openerService: OpenerService;

    protected render(): React.ReactNode {
        const aboutTab = <div className={ABOUT_CONTENT_CLASS}>
            <img src={logo}/><br/>
            
            <p><a onClick={() => this.openLink(PRODUCT_WEBPAGE)}>{PRODUCT_WEBPAGE}</a></p>
            <p>Copyright 2021 Semantum and Yokogawa Electric Corporation</p>
            <p>modelbroker@semantum.fi</p>
            
            <table>
                <tbody>
                    <tr><TdProp>Version</TdProp><TdValue>master</TdValue></tr>
                    <tr><TdProp>Build</TdProp><TdValue>2023-10-26T04:41:46Z [44098]</TdValue></tr>
                    <tr><TdProp>Revision</TdProp><TdValue>dcb7d4386f939632dfadcfb1cf3de085c641f492</TdValue></tr>
                </tbody>
            </table>
        </div>;

        return <Tabs>
            <Tab id='about' title="About" panel={aboutTab} />
            <Tab id='license' title="License Agreement" panel={<LicenseText/>} />
            <Tab id='acknowledgments' title="Acknowledgments" panel={<Acknowledgments openLink={this.openLink}/>} />
        </Tabs>;        
    }

    private openLink = async (link: string): Promise<void> => {
        console.log("openLink", link);
        const uri = new URI(link);
        const opener = await this.openerService.getOpener(uri);
        opener.open(uri);
    }
}

import { CommandContribution, MenuModelRegistry } from '@theia/core';
import { FrontendApplicationContribution, KeybindingContribution, WidgetFactory } from '@theia/core/lib/browser';
import { TabBarToolbarFactory } from '@theia/core/lib/browser/shell/tab-bar-toolbar';
import { createFileNavigatorContainer } from '@theia/navigator/lib/browser/navigator-container';
import { FileNavigatorWidget, FILE_NAVIGATOR_ID } from '@theia/navigator/lib/browser/navigator-widget';
import { Container, ContainerModule, interfaces } from 'inversify';
import { CustomMenuModelRegistry } from './custom-menu-model-registry';
import { CustomToolBar } from './custom-tool-bar';
import { RemovalContribution } from './removal-contribution';
import { RestrictedFileNavigatorWidget } from './restricted-file-navigator-widget';


export default new ContainerModule((bind, unbind, isBound, rebind) => {
    bindRemovalContribution(bind, isBound, rebind);
});

export const bindRemovalContribution = (bind: interfaces.Bind, isBound: interfaces.IsBound, rebind: interfaces.Rebind) => {
    if (isBound(MenuModelRegistry)) {
        console.log("MenuModelRegistry is already bound!");
        bind(CustomMenuModelRegistry).toSelf().inSingletonScope();
        bind(CommandContribution).to(CustomMenuModelRegistry);
        rebind(MenuModelRegistry).toService(CustomMenuModelRegistry);
    }

    bind(KeybindingContribution).to(RemovalContribution);
    bind(FrontendApplicationContribution).to(RemovalContribution);

    bind(RestrictedFileNavigatorWidget).toDynamicValue(ctx =>
        createRestrictedFileNavigatorWidget(ctx.container)
    );

    bind(WidgetFactory).toDynamicValue(({ container }) => ({
        id: FILE_NAVIGATOR_ID,
        createWidget: () => container.get(RestrictedFileNavigatorWidget)
    })).inSingletonScope();

    rebind(TabBarToolbarFactory).toFactory(context => () => {
        const container = context.container.createChild();
        container.bind(CustomToolBar).toSelf().inSingletonScope();
        return container.get(CustomToolBar);
    });
}

export function createRestrictedFileNavigatorWidget(parent: interfaces.Container): RestrictedFileNavigatorWidget {
    return createRestrictedFileNavigatorContainer(parent).get(RestrictedFileNavigatorWidget);
}

export function createRestrictedFileNavigatorContainer(parent: interfaces.Container): Container {
    const container = createFileNavigatorContainer(parent);
    container.unbind(FileNavigatorWidget);
    container.bind(RestrictedFileNavigatorWidget).toSelf();
    return container;
}
import { Emitter, environment, Event, ILogger, MaybePromise, MessageService, ProgressService } from "@theia/core";
import { NavigatableWidgetOpenHandler, NavigatableWidgetOptions, WidgetOpenerOptions } from "@theia/core/lib/browser";
import URI from "@theia/core/lib/common/uri";
import { PropertyViewWidget } from '@theia/property-view/lib/browser/property-view-widget';
import { inject, postConstruct } from "inversify";
import { LicenseStatus } from "../../common/license-service";
import { LicenseClientImpl } from "../license-client";
import { XmlViewerWidget } from "./xml-viewer-widget";

export interface WidgetId {
    id: number;
    uri: string;
}

export interface XmlViewerOpenerOptions extends WidgetOpenerOptions {
    selection?: string[]; // TODO: this could be DOM id or something in XML document?
    preview?: boolean;
    counter?: number
}

export class XmlViewerManager extends NavigatableWidgetOpenHandler<XmlViewerWidget> {

    readonly id = XmlViewerWidget.WIDGET_ID;

    readonly label = 'Dexpi viewer';

    @inject(ILogger) protected readonly logger: ILogger;
    @inject(LicenseClientImpl) protected readonly licenseClient: LicenseClientImpl;
    @inject(MessageService) protected readonly messageService: MessageService;
    @inject(ProgressService) protected readonly progressService: ProgressService;

    protected readonly viewerCounters = new Map<string, number>();

    protected readonly onActiveViewerChangedEmitter = new Emitter<XmlViewerWidget | undefined>();
    /**
     * Emit when the active editor is changed.
     */
    readonly onActiveEditorChanged: Event<XmlViewerWidget | undefined> = this.onActiveViewerChangedEmitter.event;

    protected readonly onCurrentViewerChangedEmitter = new Emitter<XmlViewerWidget | undefined>();
    /**
     * Emit when the current editor is changed.
     */
    readonly onCurrentViewerChanged: Event<XmlViewerWidget | undefined> = this.onCurrentViewerChangedEmitter.event;

    private licenseActive: boolean = false;
    private checkLicense: boolean = environment.electron.is();

    @postConstruct()
    protected init(): void {
        super.init();

        this.licenseClient.licenseStatus()
            .then((status) => this.updateLicenseStatus(status));

        console.log('xml-viewer-manager: setting licenseService client');

        this.licenseClient.onLicenseStatusChanged((status) => {
            this.logger.info('updating license status ' + JSON.stringify(status));
            this.updateLicenseStatus(status);
        });

        console.log('xml-viewer-manager: client is now set');

        this.shell.onDidChangeActiveWidget(() => this.updateActiveViewer());
        this.shell.onDidChangeCurrentWidget(() => this.updateCurrentViewer());
        this.onCreated(widget => {
            widget.onDidChangeVisibility(() => {
                if (widget.isVisible) {
                    this.addRecentlyVisible(widget);
                } else {
                    this.removeRecentlyVisible(widget);
                }
                this.updateCurrentViewer();
            });
            this.checkCounterForWidget(widget);
            widget.disposed.connect(() => {
                this.removeFromCounter(widget);
                this.removeRecentlyVisible(widget);
                this.updateCurrentViewer();
            });
        });
        for (const widget of this.all) {
            if (widget.isVisible) {
                this.addRecentlyVisible(widget);
            }
        }
        this.updateCurrentViewer();
    }

    private updateLicenseStatus(status: LicenseStatus): void {
        this.licenseActive = status.active;
        if (!this.licenseActive) {
            for (const widget of this.all) {
                this.shell.closeWidget(widget.id);
            }
        }
    }

    protected async getWidget(uri: URI, options?: XmlViewerOpenerOptions): Promise<XmlViewerWidget | undefined> {
        return this.progressService.withProgress('', 'explorer', async () => {
            if (!this.licenseActive && this.checkLicense) {
                this.messageService.error('No active license');
                return Promise.reject(' no active license ');
            }
            const optionsWithCounter: XmlViewerOpenerOptions = { counter: this.getCounterForUri(uri), ...options };
            const viewer = await super.getWidget(uri, optionsWithCounter);
            if (viewer) {
                // Reveal selection before attachment to manage nav stack. (https://github.com/eclipse-theia/theia/issues/8955)
                this.revealSelection(viewer, optionsWithCounter, uri);
            }
            // open property view
            this.shell.revealWidget(PropertyViewWidget.ID);
            return viewer;
        });
    }

    protected async getOrCreateWidget(uri: URI, options?: XmlViewerOpenerOptions): Promise<XmlViewerWidget> {
        return this.progressService.withProgress('', 'explorer', async () => {
            if (!this.licenseActive && this.checkLicense) {
                this.messageService.error('No active license');
                return Promise.reject(' no active license ');
            }
            const counter = options?.counter === undefined ? this.getOrCreateCounterForUri(uri) : options.counter;
            const optionsWithCounter: XmlViewerOpenerOptions = { ...options, counter };
            const viewer = await super.getOrCreateWidget(uri, optionsWithCounter);
            // Reveal selection before attachment to manage nav stack. (https://github.com/eclipse-theia/theia/issues/8955)
            this.revealSelection(viewer, options, uri);
            // open property view
            this.shell.revealWidget(PropertyViewWidget.ID);
            return viewer;
        });
    }


    protected revealSelection(viewer: XmlViewerWidget, input?: XmlViewerOpenerOptions, uri?: URI): void {
        if (input && input.selection) {
            viewer.setSelection(input.selection);
        }
    }

    protected readonly recentlyVisibleIds: string[] = [];
    protected get recentlyVisible(): XmlViewerWidget | undefined {
        const id = this.recentlyVisibleIds[0];
        return id && this.all.find(w => w.id === id) || undefined;
    }
    protected addRecentlyVisible(widget: XmlViewerWidget): void {
        this.removeRecentlyVisible(widget);
        this.recentlyVisibleIds.unshift(widget.id);
    }
    protected removeRecentlyVisible(widget: XmlViewerWidget): void {
        const index = this.recentlyVisibleIds.indexOf(widget.id);
        if (index !== -1) {
            this.recentlyVisibleIds.splice(index, 1);
        }
    }

    protected _activeViewer: XmlViewerWidget | undefined;
    /**
     * The active viewer.
     * If there is an active viewer (one that has focus), active and current are the same.
     */
    get activeEditor(): XmlViewerWidget | undefined {
        return this._activeViewer;
    }
    protected setActiveViewer(active: XmlViewerWidget | undefined): void {
        if (this._activeViewer !== active) {
            this._activeViewer = active;
            this.onActiveViewerChangedEmitter.fire(this._activeViewer);
        }
    }
    protected updateActiveViewer(): void {
        const widget = this.shell.activeWidget;
        this.setActiveViewer(widget instanceof XmlViewerWidget ? widget : undefined);
    }

    protected _currentViewer: XmlViewerWidget | undefined;
    /**
     * The most recently activated viewer (which might not have the focus anymore, hence it is not active).
     * If no viewer has focus, e.g. when a context menu is shown, the active editor is `undefined`, but current might be the viewer that was active before the menu popped up.
     */
    get currentViewer(): XmlViewerWidget | undefined {
        return this._currentViewer;
    }
    protected setCurrentViewer(current: XmlViewerWidget | undefined): void {
        if (this._currentViewer !== current) {
            this._currentViewer = current;
            this.onCurrentViewerChangedEmitter.fire(this._currentViewer);
        }
    }
    protected updateCurrentViewer(): void {
        const widget = this.shell.currentWidget;
        if (widget instanceof XmlViewerWidget) {
            this.setCurrentViewer(widget);
        } else if (!this._currentViewer || !this._currentViewer.isVisible || this.currentViewer !== this.recentlyVisible) {
            this.setCurrentViewer(this.recentlyVisible);
        }
    }

    canHandle(uri: URI, options?: WidgetOpenerOptions): MaybePromise<number> {
        if (uri.path.ext.toLowerCase() === '.xml') {
            return 1000;
        }
        return 0;
    }

    protected createWidgetOptions(uri: URI, options?: WidgetOpenerOptions): NavigatableWidgetOptions {
        return {
            kind: 'navigatable',
            uri: this.serializeUri(uri)
        };
    }

    protected serializeUri(uri: URI): string {
        return uri.withoutFragment().toString();
    }

    protected removeFromCounter(widget: XmlViewerWidget): void {
        const { id, uri } = this.extractIdFromWidget(widget);
        if (uri && !Number.isNaN(id)) {
            let max = -Infinity;
            this.all.forEach(editor => {
                const candidateID = this.extractIdFromWidget(editor);
                if ((candidateID.uri === uri) && (candidateID.id > max)) {
                    max = candidateID.id!;
                }
            });

            if (max > -Infinity) {
                this.viewerCounters.set(uri, max);
            } else {
                this.viewerCounters.delete(uri);
            }
        }
    }

    protected extractIdFromWidget(widget: XmlViewerWidget): WidgetId {
        const uri = widget.uri.toString();
        const id = Number(widget.id.slice(widget.id.lastIndexOf(':') + 1));
        return { id, uri };
    }

    protected checkCounterForWidget(widget: XmlViewerWidget): void {
        const { id, uri } = this.extractIdFromWidget(widget);
        const numericalId = Number(id);
        if (uri && !Number.isNaN(numericalId)) {
            const highestKnownId = this.viewerCounters.get(uri) ?? -Infinity;
            if (numericalId > highestKnownId) {
                this.viewerCounters.set(uri, numericalId);
            }
        }
    }

    protected getCounterForUri(uri: URI): number | undefined {
        return this.viewerCounters.get(uri.toString());
    }

    protected getOrCreateCounterForUri(uri: URI): number {
        return this.getCounterForUri(uri) ?? this.createCounterForUri(uri);
    }

    protected createCounterForUri(uri: URI): number {
        const identifier = uri.toString();
        const next = (this.viewerCounters.get(identifier) ?? 0) + 1;
        return next;
    }

    setConnectivityMode(connectivityMode: boolean) {
        this.widgetManager.getWidgets(XmlViewerWidget.WIDGET_ID)
            .forEach((widget: XmlViewerWidget) => widget.setConnectivityMode(connectivityMode));
    }
    
    setColorMode(colorMode: number) {
        this.widgetManager.getWidgets(XmlViewerWidget.WIDGET_ID)
            .forEach((widget: XmlViewerWidget) => widget.setColorMode(colorMode));
    } 
}
import { TabBarToolbar } from '@theia/core/lib/browser/shell/tab-bar-toolbar';
import { injectable } from "inversify";
import * as React from 'react';


/**
 * Toolbar without 'More actions' icon
 */
@injectable()
export class CustomToolBar extends TabBarToolbar {

     protected renderMore(): React.ReactNode {
        return <div/>;
    }

}